const Version = [
    {
        ver: "2.0.5",
        des: "Add gate value in temperature detail page"
    },
    {
        ver: "2.0.4",
        des: "show counter details and add temperature detail page with infinite scroll"
    },
    {
        ver: "2.0.3",
        des: "Temperature dashboard added on temp.flovation.in "
    },
    {
        ver: "2.0.1",
        des: "controller section added"
    },
    {
        ver: "1.0.1",
        des: " implement tvCode"
    }
]

export default Version;

//top is the latest one 