import React from 'react'
import { useNavigate } from 'react-router-dom';
import "../Styles/TvCode.css"
import axios from "axios";
import { setTvDetails, getTvDetails } from "../Utility/AsyncStorageData"
import flovation from "../Assets/Logo2.png"
import Version from '../Utility/Version';
import QRCode from "react-qr-code";
var intervalId = 0;



function TvCode() {


  let navigate = useNavigate();
  let d = getTvDetails()
  // let value = "QEZ1703572266XM";
  // var tvCode = "";

  const [tvCode, setTvCode] = React.useState(generateRandomString());
  const [error, setError] = React.useState(null);
  const [load, setLoad] = React.useState(d ? d.length : 0);

  // console.log("**************login*****************", load)

  function generateRandomString() {
    const generateRandomChars = (length) => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    };

    const firstThreeChars = generateRandomChars(3);
    const unixTime = Math.floor(Date.now() / 1000);
    const lastTwoChars = generateRandomChars(2);

    const randomString = `${firstThreeChars}${unixTime}${lastTwoChars}`;
    // tvCode = randomString

    return randomString;
  }

  // const randomString = generateRandomString();
  // console.log(randomString);


  const verifyTvCode = () => {
    // console.log("login Api")
    try {

      // console.log("getTvDetails", d)


      if (d == null && tvCode.length === 0) {
        return
      }


      let url = process.env.REACT_APP_BASE_URL + "/tv/verifyTvCode";
      let body = {
        tv_code: d != null ? d.tv_code : tvCode,
      }

      // console.log("response: ", body, url);
      axios.post(url, body).then(response => {

        if (response.status === 200) {
          if (response.data.statusCode === 0) {
            // console.log("response: ", response.data.data);
            setTvDetails(JSON.stringify(response.data.data));
            navigate("/dashboard");

          }

          else {
            console.log("response: ", response.data.msg);
          }
        }

      }).catch(error => {
        if (error.response.status === 403) {
          navigate("/");
        }
      });

    } catch (error) {
      console.log("ERROR");

    }

  }

  

  clearInterval(intervalId);
  intervalId = setInterval(() => {
    verifyTvCode();
  }, 5000);


  React.useEffect(() => {
    // verifyTvCode()
    return () => {
      // 👇️ clear timeout when the component unmounts
      clearInterval(intervalId);
    };
  }, []);


  return (
    <div>
      {load != 0 ?
        <div className='load'> <img src="https://tmmapi.9930i.com/loader" alt="Computer man" style={{ height: "150px" }} />
        </div> :

        <div className='TvCode_block'>
          <div className='Tv_Header'>
            <h1 className='heading'>SuperManager</h1>
            <div className='inputSection' >
              <label>TV Code</label>
              <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={tvCode}
                  viewBox={`0 0 256 256`}
                />
              </div>
              <h3 className='qr_codeName'>{tvCode}</h3>
              {/* <input className='name' type="text" placeholder="Enter TV Code"
            name="tvCode"
            value={tvCode}
            onChange={handleTvCodeChange}
          /> */}
              {/* <button className='nextButton' onClick={verifyTvCode}>Next</button> */}
            </div>

            <p className='error'>{error}</p>
          </div>

          <div className='logo_Block'>
            <img src={flovation} alt="flovation_Logo" />
            <p className='versionText' >Version {Version[0].ver}</p>
          </div>
        </div>
      }
    </div>
  )
}

export default TvCode






