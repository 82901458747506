import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "../Styles/TempratureStats.css";
import TempratureNavbar from "../Temperature/TempratureNavbar";
import { useLocation } from 'react-router-dom';

const TempratureStats = () => {
  const [stats, setStats] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const fetchDeviceList = async () => {
    try {
      const cityDetails = location.state;

      let body = { city_id: cityDetails.city_id };
      if (cityDetails.flag == 1) body.temprature_alert_day = 1;

      let url =
        process.env.REACT_APP_BASE_URL + "/temprature/fetchDeviceStatsList";
      await axios
        .post(url, body)
        .then((response) => {
          if (response.status === 200 && response.data.statusCode === 0) {
            setStats(response.data.data);
          } else {
            console.log("Error: ", response.data.msg);
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    } catch (error) {
      console.log("Error: ", error);
    }
  };
  useEffect(() => {
    fetchDeviceList();
  }, []);

  const downloadExcel = () => {
    // Use a library like xlsx to generate and download Excel file
    // This is a placeholder for actual implementation
    alert("Excel file downloaded");
  };

  return (
    <div className="main">
      <div>
        <TempratureNavbar />
      </div>


      <div className="dashboard">
        {stats.map((stat, index) => (
          <div className="tempStatsDetail" style={{
            backgroundColor: (stat.temprature_alert_flag == 0 ? "none" : "rgb(242 198 198)")
          }}>
            <div className="deviceName">
              <div className="stats1">
                <h3>{stat.device_name}</h3>
              </div>
              <div className="connectStatus" style={{
                backgroundColor: (stat.device_status == 'C' ? "#77DD77" : "#FF8A8A"),
                color: (stat.device_status == 'C' ? "green" : "red"),
                border: (stat.device_status == 'C' ? "#228B22" : "#D10000"),
                borderWidth: '1px',
              }}>
                {stat.device_status == 'C' ? <h4>Active</h4> : <h4>Inactive</h4>}
              </div>
            </div>

            <h4 style={{ margin: "2px 10px 10px 10px" }}>{stat.store_name}</h4>
            <div className="alertBlock">
              <div className="lastAlert" style={{
                backgroundColor: (stat.temprature_alert_day == 0 ? "#77DD77" : "#FF8A8A"),
                opacity: "0.7"
              }}>
                <h4>Alert in Last 24 Hours</h4>
                {stat.temprature_alert_day == 0 ? <h4>No</h4> : <h4>Yes</h4>}
              </div>
              <div className="TempActions">
                <i class="fa-solid fa-file-arrow-down" onClick={downloadExcel}></i>
                <i class="fa-solid fa-circle-info" onClick={() => navigate("/tempDetailsLog", { state: stat })}></i>

              </div>
            </div>
          </div>

        ))}

        <div className="footer">
          <h6 style={{ width: "30px", backgroundColor: "rgb(242 198 198)", marginRight: "5px", borderRadius: "5px" }} ></h6>
          <h6 style={{ marginRight: "20px" }}>Currently in Alert</h6>


          <h6 style={{ width: "30px", backgroundColor: "#E6EEF7", marginRight: "5px", borderRadius: "5px" }} ></h6>
          <h6>In Normal Condition</h6>
        </div>
      </div>

    </div>
  );
};

export default TempratureStats;
