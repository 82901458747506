import React, { useState } from 'react'
import "../Styles/MachineLog.css"
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import axios from "axios";
import { getUserDetails } from "../Utility/AsyncStorageData";
import * as FileSaver from 'file-saver';
import { useNavigate } from 'react-router-dom';

function Report(props) {

    let navigate = useNavigate();
    // var Logs = getSensorLog();
    var userDetails = getUserDetails();
    var reportName = props.reportName ?? userDetails.comp_name
    // console.log("logs", userDetails)

    const [error, setError] = React.useState('');
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'days').format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [optionList, setOptionList] = useState();
    // const [reportSensorList, setReportSensorList]= useState([])

    function handleSelect(data) {
        // console.log("selectedOptions****************", data)
        setSelectedOptions(data);
    }


    const handleDateFrom = (changedDate) => {
        setFromDate(moment(changedDate).format('YYYY-MM-DD'))
        // date1.current = changedDate
        // console.log("Date From:", moment(changedDate).format('YYYY-MM-DD'));
    }

    const handleDateTo = (changedDate) => {
        setToDate(moment(changedDate).format('YYYY-MM-DD'))
        // date1.current = changedDate
        // console.log("Date to:", moment(changedDate).format('YYYY-MM-DD'));
    }

    const selectDeviceWiseMachines = async () => {
        let options = []
        setOptionList(options)

        try {
            console.log('*********** fetching DeviceWiseMachines list api ***********');

            let url = process.env.REACT_APP_BASE_URL + '/sensor/getSensorList';
            let body = {
                device_id: props.deviceData,
            };

            // console.log(" fetching DeviceWiseMachines list body", body)
            await axios
                .post(url, body, {
                    headers: {
                        authorization: `${userDetails.access_token}`,
                    },
                })
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.statusCode === 0) {
                            for (let key of response.data.result)
                                options.push({ value: key._id, label: key.sensor_name })
                            setOptionList(options)
                        }

                        // console.log('***********  fetching DeviceWiseMachines list ***********', response.data.result);
                    }
                })
                .catch(error => {
                    console.log("ERROR", error)
                    if (error.response.status === 403) {
                        navigate("/");
                      }
                    setError('ERROR');
                    setTimeout(() => errortimer(), 2000);
                });
            // setLoading(false);

        } catch (error) {
            console.log('error ', error);
            throw error;
        }

    }



    const showDeviceMachineId = () => {
        if (props.show == 1 || props.show == 5) {
            return (
                // <h1>sdfghyu</h1>
                <div className="dropdown" style={{ marginTop: "10px" }}>
                    {/* <h2 style={{marginBottom: "10px", fontSize:"15px"}}>Select Machines</h2> */}
                    <div className="dropdown-container">
                        <Select
                            options={optionList}
                            placeholder="Select Machines"
                            value={selectedOptions}
                            onChange={handleSelect}
                            isMulti
                        />
                    </div>
                </div>
            )
        }
        else {
            return <></>
        }
    }



    const downloadExcel = async () => {

        // console.log('*********** reportDownload ***********', fromDate, toDate);
        var device_id = 0
        var machine_id = 0
        var comp_num = userDetails.comp_num

        if (props.show == 5) {
            comp_num = props.company
        }

        if (props.show == 0) {
            device_id = props.deviceData.device_id
            machine_id = props.deviceData.machine_id ?? undefined
        }

        else if (props.show == 2) {
            device_id = props.device_id
            machine_id = props.machine_id ?? undefined
        }

        else if (props.show == 4) {
            device_id = undefined
            machine_id = undefined
            comp_num = props.deviceData
        }

        else {
            device_id = props.deviceData
            let temp = ""
            if (selectedOptions.length) {
                for (let i = 0; i < selectedOptions.length; i++) {
                    if (i == selectedOptions.length - 1) {
                        temp = temp + selectedOptions[i].value
                    }
                    else {
                        temp = temp + selectedOptions[i].value + ","
                    }
                }
            }
            else {
                temp = undefined
            }
            machine_id = temp
        }
        try {

            let url = process.env.REACT_APP_BASE_URL + "/reports/machineWiseReports";
            let body = {
                comp_num: comp_num,
                from: fromDate,
                to: toDate,
                download: "1",
                device_id: device_id,
                machine_id: machine_id
            };
            // console.log('*********** ***********', body);
            const response = await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.token}`
                }, responseType: 'blob'
            })
            var blob = new Blob([response.data], {
                type: 'application/vnd.ms-excel',
            });
            let fileDate = new Date()
            var name = `/${reportName}_Machine_Report_${fileDate.getDate()}-${fileDate.getMonth() + 1}-${fileDate.getFullYear()}.xlsx`;
            // console.log("filename*****", name)
            FileSaver.saveAs(blob, name);
            // console.log("blob*****", blob)
            props.sendDataToParent('close report')



        } catch (error) {
            console.log('error ', error);
            if (error.response.status === 403) {
                navigate("/");
              }
            props.sendDataToParent('close report')
            throw error;
        }
    }

    const downloadUserReport = async() => {
        console.log("download user report")
        try {

            let url = process.env.REACT_APP_BASE_URL + "/reports/userLoginWiseReport";
            let body = {
                from: fromDate,
                to: toDate
            };
            // console.log('*********** ***********', body);
            const response = await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.token}`
                }, responseType: 'blob'
            })
            var blob = new Blob([response.data], {
                type: 'application/vnd.ms-excel',
            });
            let fileDate = moment().format('YYYY_MM_DD_HH_mm_ss')
            var name = `/UserLogin_Report_${fileDate}.xlsx`;
            // console.log("filename*****", name)
            FileSaver.saveAs(blob, name);
            // console.log("blob*****", blob)
            props.sendDataToParent('close report')



        } catch (error) {
            console.log('error ', error);
            if (error.response.status == 403) {
                navigate("/");
              }
            props.sendDataToParent('close report')
            throw error;
        }
    }




    const sendMail = () => {
        // console.log("sendMail", fromDate, toDate);
        props.sendDataToParent('close report')

    }

    const errortimer = () => {
        setError(undefined)
    }

    React.useEffect(() => {
        if (props.show == 1 || props.show == 5) {
            // console.log("sdfghj")
            selectDeviceWiseMachines()
        }
    }, []);


    return (
        <div>
            <div>
                <DatePicker
                    selected={fromDate}
                    placeholderText='Select From Data'
                    onChange={handleDateFrom}
                    className="custom-datepicker-report"
                    dateFormat="YYYY/MM/dd"
                    maxDate={new Date()}
                />
            </div>

            <div>
                <DatePicker
                    selected={toDate}
                    placeholderText='Select To Data'
                    onChange={handleDateTo}
                    className="custom-datepicker-report"
                    dateFormat="YYYY/MM/dd"
                    maxDate={new Date()}
                    minDate={fromDate}
                />
            </div>

            {showDeviceMachineId()}

            <div className='buttons'>
                <button onClick={ props.show == 7 ? downloadUserReport : downloadExcel}>Download Excel</button>
                { props.show == 7 ? <></> :  <button onClick={sendMail}>Send Via Mail</button> }
            </div>

            {error ? <p>{error}</p> : <></>}
        </div>
    )
}

export default Report