import React from 'react'
import Navbar from './Navbar'
import "../Styles/DeviceTable.css"
import "../Styles/UserCard.css"
import { getUserDetails } from "../Utility/AsyncStorageData";
import axios from "axios";
import { ToggleSlider } from "react-toggle-slider";
import Header from "./Header";
import { useNavigate } from 'react-router-dom';


import flovation from "../Assets/logo1.png"

function WebDevice() {

  var userDetails = getUserDetails();
  let navigate = useNavigate();

  const [userList, setUserList] = React.useState([
    {
      comp_num: 0,
      contact: "NA",
      device_id: [],
      email: "NA",
      machine_id: [],
      name: "Guest",
      role_id: "A",
      user_id: 21,
      is_active: "Y"
    }
  ]);
  const [error, setError] = React.useState('');
  const [test, setTest] = React.useState(1);



  const errortimer = () => {
    setError(undefined)
  }

  const toggleSwitch = async (index, value) => {
    try {


      let url = process.env.REACT_APP_BASE_URL + "/api/addUser";
      let body = {
        comp_num: userDetails.comp_num,
        user_id: value.user_id,
        is_active: value.is_active == "Y" ? "N" : "Y"

      }
      // console.log("***********toggleSwitch************* ", body)
      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {
        // console.log("response: ", response.data);

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            setUserList(prevUserList => {
              let temp = [...prevUserList]
              temp[index].is_active = temp[index].is_active == "Y" ? "N" : "Y"
              return temp
            })
          }

          else {

            setError(response.data.msg)
            setTimeout(() => errortimer(), 2000)
            console.log(" addUser error: ", response.data.msg);
          }

          // setLoading (false);
        }
      }).catch(error => {
        console.log("error: ", error);
        if (error.response.status == 403) {
            navigate("/");
          }
        setError("ERROR")
        setTimeout(() => errortimer(), 2000)
        return
      })
    } catch (error) {
      console.log("error ", error)
      throw error;
    }

  }

  const handleFetchUsers = async () => {
    try {

      let url = process.env.REACT_APP_BASE_URL + "/api/listOfUser";
      let body = {
        comp_num: userDetails.comp_num,
        user_id: userDetails.user_id
      }
      // console.log("--------------listOfUser------------- ", body)
      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {
        // console.log("response: ", response.data);

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            setUserList(response.data.list)
            // if (response.data.list.length === 0) {
            //   setShowData(true);
            // }
            // else {
            //   setShowData(false);
            // }
          }

          else {

            setError(response.data.msg)
            setTimeout(() => errortimer(), 2000)
            console.log("listOfUser error: ", response.data.msg);
          }

          // setLoading (false);
        }

        else if (response.status == 403) {
          navigate("/");
        }
      }).catch(error => {
        console.log("error: ", error);
        if (error.response.status == 403) {
            navigate("/");
          }
        setError("ERROR")
        setTimeout(() => errortimer(), 2000)
        return
      })

    } catch (error) {
      console.log("error ", error)
      throw error;
    }

  }


  const navbarRender = (data) => {
    // console.log('Data received from child:', data);
    setTest(!test)
  };


  React.useEffect(() => {
    handleFetchUsers();
  }, []);


  return (
    <div className='main'>

      <div>
        <Navbar />
      </div>

      <div className='Admin_menu'>
        {/* <div className='Header'>
          <div style={{ width: "10%" }}>
            <img src={flovation} alt="flovation_Logo" />
          </div>
          <div style={{ width: "70%" }} className='com_name'>
            <h3>SuperManager</h3>
            <h4>{userDetails.comp_name}</h4>
          </div>
        </div> */}

        <Header show={1} sendNavbarToParent={navbarRender} />

        <h3 style={{ width: "100%", textAlign: "center", color: "rgb(5, 19, 120)" }}>Users</h3>
        <div className='user'>
          {userList.map((item, index) => (
            <div key={index} className='user_card'>
              <div className='row_1'>
                <div className='user_name1'>
                  <h5>{index + 1}.</h5>
                  <h5 style={{ marginLeft: "10px" }}>{item.name}</h5>
                </div>
                <h4>{item.role_id == 'A' ? ("Admin") : (item.role_id == 'O' ? ("Owner") : (item.role_id == 'F' ? ("Floor Manager") : ("Machine Incharge")))}</h4>
              </div>


              {(item.email !== "NA") ? (
                <div className='row_2'>
                  <h5 style={{ marginLeft: "20px" }}>{item.email}</h5>

                  {item.contact == "NA" ? (
                    <ToggleSlider onToggle={() => { toggleSwitch(index, item) }}
                      style={{ marginRight: "30px" }}
                      active={item.is_active == "Y" ? (true) : (false)} />
                  ) : (<></>)}

                </div>) : (<></>)}

              {(item.contact !== "NA") ? (
                <div className='row_2'>
                  <h5 style={{ marginLeft: "20px" }}>{item.contact}</h5>

                  <ToggleSlider onToggle={() => { toggleSwitch(index, item) }}
                    style={{ marginRight: "30px" }}
                    active={item.is_active == "Y" ? (true) : (false)} />
                </div>) : (<></>)}
            </div>
          ))}


        </div>

      </div>
    </div >
  )
}

export default WebDevice
