import React from 'react'
import ControllerNavbar from '../../WebAppComponents/ControllerNavbar'
import "../../Styles/DeviceTable.css"
import "../../Styles/UserCard.css"
import "../../Styles/ControllerDashboard.css"
import WebMap from '../WebMap'
import axios from "axios";
import Header from "../../WebAppComponents/Header";
import { getUserDetails } from "../../Utility/AsyncStorageData";
import { useNavigate } from 'react-router-dom';
import copy from "copy-to-clipboard";

function ControllerOtps() {
    let navigate = useNavigate();
    var userDetails = getUserDetails();


    const [test, setTest] = React.useState(1);
    const [compDetail, setCompDetail] = React.useState({})
    const [error, setError] = React.useState('');
    const [openRowIndex, setOpenRowIndex] = React.useState(null);

    
    const [copyText, setCopyText] = React.useState(false);
 
    
    const copyToClipboard = (item, index) => {
        copy(item);

        setCopyText(true)
        setOpenRowIndex(index === openRowIndex ? null : index);
        setTimeout(() => setCopyText(false), 1000)
    };

    

    const navbarRender = (data) => {
        // console.log('Data received from child:', data);
        setTest(!test)
    };
    const errortimer = () => {
        setError(undefined)
    }

    const listOfOtp = async () => {
        try {

            let url = process.env.REACT_APP_BASE_URL + "/api/listOfOtp";
            let body = {
            }
            console.log("************handleUserData************ ")
            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        setCompDetail(response.data.list)
                        setError(undefined)

                    }
                    else if (response.data.statusCode === 1) {
                        setError(undefined);
                    }


                    else {

                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                        console.log(" companyWiseDeviceList error: ", response.data.msg);
                    }

                    // setLoading (false);
                }

                else if (response.status == 403) {
                    navigate("/");
                }
            }).catch(error => {
                console.log("error: ", error);
                if (error.response.status == 403) {
                    navigate("/");
                }

                return
            })
        } catch (error) {
            console.log("error1 ", error)
            return
            // throw error;
        }
    }

    React.useEffect(() => {
        listOfOtp()
    }, []);

    return (
        <div className='main'>

            <div>
                <ControllerNavbar />
            </div>

            <div className='Admin_menu' style={{ marginTop: "20px" }}>
                <Header show={3} sendNavbarToParent={navbarRender} />

                <div style={{ width: "90%", justifyContent: "flex-end", display: "flex", margin: "0 5%" }}>
                    <div style={{
                        display: "inline-flex", flexDirection: "row", alignItems: "center", backgroundColor: "white", padding: "10px",
                        borderRadius: "10px"
                    }} onClick={()=>{listOfOtp()}}>
                        <i class="fa-solid fa-arrows-rotate" style={{ fontSize: "16px", color: "#254e6f" }}></i>
                        <h5 style={{ margin: 0, padding: "0 10px", color: "#254e6f" }}>Reload</h5>
                    </div>
                </div>

                <h3 style={{ margin: 0, padding: "0 5px", color: "#254e6f", textAlign: "center", fontWeight: "600" }}>List Of Otps</h3>

                <div className='device_table' style={{ marginTop: "20px" }}>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ borderRadius: "10px 0 0 10px" }}>S no</th>
                                <th> Company Email/Contact</th>
                                <th style={{ borderRadius: " 0 10px 10px 0" , width: "150px" }}>OTP</th>
                            </tr>
                        </thead>

                        <tbody>
                            {compDetail.length ?
                                (compDetail.map((item, index) => (
                                    <tr className="device_tr" key={index} >
                                        <td style={{ borderRadius: "10px 0 0 10px" }}>{index + 1}</td>
                                        <td >{item.email}</td>
                                        <td className='action' style={{ borderRadius: " 0 10px 10px 0",display:"flex", alignItems:"center", width: "150px" }}>
                                            {item.otp}
                                        <i class="fa-solid fa-copy" style={{ fontSize: "15px", color: "#254e6f", margin:"0 10px" }} onClick={()=> copyToClipboard(item.otp, index)}></i>
                                        {(copyText && openRowIndex === index )? <p style={{color:"gray", margin:"0"}}>Copied!</p>: <></>}
                                        </td>
                                    </tr>
                                )))
                                : <></>
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    )
}

export default ControllerOtps