
export const setTvDetails = (data) => {
  localStorage.setItem("tv_details", data);
}

export const getTvDetails = () => {
  return JSON.parse(localStorage.getItem("tv_details")) || null;
}

export const removeTvDetails = () => {
  // console.log("remove tv_details ")
  localStorage.removeItem("tv_details");
};

export const setUserDetails = (data) => {
  localStorage.setItem("user_Details", data);
}

export const getUserDetails = () => {
  return JSON.parse(localStorage.getItem("user_Details")) || null;
}


export const removeUserDetails = () => {
  localStorage.removeItem("user_Details");
};



export const setDeviceDetails = (sensorData) => {
  localStorage.setItem("sensor_Data", sensorData);
}

export const getDeviceDetails = () => {
  return JSON.parse(localStorage.getItem("sensor_Data")) || null;
}

export const setSensorLog = (sensorLog) => {
  localStorage.setItem("sensor_Log", sensorLog);
}

export const getSensorLog = () => {
  return JSON.parse(localStorage.getItem("sensor_Log")) || null;
}

export const setSensorOnTime = (sensorOnTime) => {
  localStorage.setItem("sensor_OnTime", sensorOnTime);
}

export const getSensorOnTime = () => {
  return JSON.parse(localStorage.getItem("sensor_OnTime")) || null;
}


export const SetDeviceMachines = (DeviceMachines) => {
  localStorage.setItem("deviceMachines", DeviceMachines);
}

export const getDeviceMachines = () => {
  return JSON.parse(localStorage.getItem("deviceMachines")) || null;
}

export const setStats = (Stats) => {
  localStorage.setItem("stats", Stats);
}

export const getsetStats = () => {
  return JSON.parse(localStorage.getItem("stats")) || null;
}

export const setCompNum = (CompNum) => {
  localStorage.setItem("CompNum", CompNum);
}

export const getCompNum  = () => {
  return JSON.parse(localStorage.getItem("CompNum")) || null;
}
export const removeCompNum = () => {
  localStorage.removeItem("CompNum");
};

export const setDeviceId = (DeviceId) => {
  localStorage.setItem("DeviceId", DeviceId);
}

export const getDeviceId  = () => {
  return JSON.parse(localStorage.getItem("DeviceId")) || null;
}
export const removeDeviceId = () => {
  localStorage.removeItem("DeviceId");
};

export const setActive = (Active) => {
  localStorage.setItem("Active", Active);
}

export const getActive  = () => {
  return JSON.parse(localStorage.getItem("Active")) || null;
}