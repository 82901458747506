import React from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const libraries = ['places'];
const mapContainerStyle = {
  // width: '70vw',
  height: '70vh',
  borderRadius: "10px",
  alignItems: "center", 
  margin: "30px 10vh"
};

const center = {
  lat: 22.7657123, // default latitude
  lng: 75.8445059, // default longitude
};


const WebMap = (props) => {
// console.log("props", props)

  const [error, setError] = React.useState('');
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBsNb2DlLCll3rqWQFOhBQQf4_miqmmuy4',
    libraries,
  });



  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div style={{flex:1, textAlign:"center"}}>Loading maps</div>;
  }

  const errortimer = () => {
    setError(undefined)
  }

  


  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={10}
        center={center}
      >
        {props.latLong.map(({ id, lat, lng }) => (
          <Marker key={id} position={{ lat, lng }} />
        ))}
      </GoogleMap>
    </div>
  );
};

export default WebMap;
