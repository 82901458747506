import React from 'react'
import Navbar from './Navbar'
import "../Styles/DeviceTable.css"
import "../Styles/UserCard.css"
import { getUserDetails } from "../Utility/AsyncStorageData";
import Header from "./Header";


import flovation from "../Assets/logo1.png"

function WebSetting() {

  var userDetails = getUserDetails();

  const [error, setError] = React.useState('');
  const [test, setTest] = React.useState(1);

  const navbarRender = (data) => {
    // console.log('Data received from child:', data);
    setTest(!test)
  };

  const errortimer = () => {
    setError(undefined)
  }

  return (
    <div className='main'>

      <div>
        <Navbar />
      </div>

      <div className='Admin_menu'>

        <Header show={1} sendNavbarToParent={navbarRender} />

        <div className='setting'>
          <span
            style={{
              fontSize: "1rem",
              fontWeight: "800",
              color: "black",
            }}
          >
            TV Dashboard Setup
          </span>
          <i className="fa fa-chevron-down" style={{ fontSize: "20px", color: "black" }}></i>
        </div>

      </div>
    </div >
  )
}

export default WebSetting
