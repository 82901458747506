import React from 'react'
// import { useNavigate } from 'react-router-dom';
import "../Styles/WebLogin.css"
import axios from "axios";
import { setUserDetails, getUserDetails, setActive, removeUserDetails } from "../Utility/AsyncStorageData"
import flovation from "../Assets/Logo2.png"
import Version from '../Utility/Version';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';



function WebLogin() {

    let navigate = useNavigate();
    let pervUser = getUserDetails()
    // console.log("pervUser ", pervUser)

    const [load, setLoad] = React.useState(true);
    const [error, setError] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [otp, setOtp] = React.useState('');
    const [otpState, setOtpState] = React.useState(true);
    const [newUser, setNewUser] = React.useState(null);




    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        input: {
            width: '40px',
            height: '40px',
            fontSize: '20px',
            margin: '0 5px',
            textAlign: 'center',
            border: '1px solid #ccc',
            borderRadius: '5px',
        }
    };

    const handleInputChange = (event) => {
        setEmail(event.target.value)
    };


    const handleLogin = async () => {

        try {
            var login_id = pervUser ? (pervUser.contact != "NA" ? pervUser.contact : pervUser.email) : undefined
            const userAgent = navigator.userAgent;
            // console.log(login_id);
            if (email || login_id) {

                let url = process.env.REACT_APP_BASE_URL + "/api/userLogin";
                let body = {
                    email: (login_id ?? email.replace(/\s+/g, "").toLowerCase()),
                    password: userAgent,
                    otp: otp.length ? otp : undefined
                }
                setEmail(body.email)
                // console.log("--------------------userLogin api------------------------", body)
                await axios.post(url, body).then(response => {

                    if (response.status === 200) {


                        // console.log("response: ", response.data.statusCode);
                        // setEmailAndPassword((email.replace(/\s+/g, "").toLowerCase()) || (email1.replace(/\s+/g, "").toLowerCase()), DeviceInfo.getDeviceId())

                        // console.log("user token ", response.data.result)
                        if (response.data.statusCode === 0) { ///for success
                            setUserDetails(JSON.stringify(response.data.result));
                            setActive(JSON.stringify("Dashboard"))
                            setOtp("")
                            setEmail("")
                            setNewUser(false)
                            setOtpState(true)

                            if (newUser === true) {
                                // console.log("new user")
                                navigate('/companyForm');
                            }
                            else {

                                setOtpState(true)
                                // console.log("Login succssefully amd navigate acc to role")


                                if (response.data.result.role_id === "A") {
                                    //   console.log(response.data.result.role_id)
                                    navigate("/webAdmin");
                                }
                                else if (response.data.result.role_id === "C") {
                                    //   console.log(response.data.result.role_id)
                                    navigate("/controllerDashboard");
                                }
                                else {
                                    //   console.log(response.data.result.role_id)
                                    navigate("/webAdmin");
                                }
                            }
                        }
                        else if (response.data.statusCode === 2) { //incorrect password

                            setNewUser(false)
                            setOtpState(false);
                            setError(response.data.msg)
                            setTimeout(() => errortimer(), 4000)
                            setLoad(false)
                            return
                        }
                        else if (response.data.statusCode === 1) { //for new user

                            setOtpState(false);
                            setNewUser(true)
                            setError(response.data.msg)
                            setTimeout(() => errortimer(), 4000)
                            setLoad(false)
                            return
                        }
                        else { 
                            setOtp("")
                            setEmail("")
                            setError(response.data.msg)
                            setTimeout(() => errortimer(), 2000)
                            return
                        }
                    }
                    else if (response.status === 401) {
                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                        navigate("/webLogin");
                        return
                    }
                    else {

                        console.log("error in userLogin: ", response.data.msg);
                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                    }
                }).catch(error => {
                    console.log("error: ", error);
                    // if (error.response.status == 403) {
                    //     navigate("/");
                    // }
                    setError("Server Error")
                    setTimeout(() => errortimer(), 2000)
                    return
                })
            }
            setLoad(false)
        } catch (error) {
            console.log("error1 ", error)
            throw error;
        }

    };

    const errortimer = () => {
        setError(undefined)
    }

    const backToLogin = () => {
        removeUserDetails()
        localStorage.clear();
        setOtpState(true)
        setEmail("")
    }

    React.useEffect(() => {
        handleLogin();
    }, []);


    return (
        <div>
            {load != 0 ?
                <div className='load'> <img src="https://tmmapi.9930i.com/loader" alt="Computer man" style={{ height: "150px" }} />
                </div> :

                <div className='Web_block'>
                    <div className='Web_Header'>
                        <h1 className='heading'>SuperManager</h1>
                        <div className='inputSection' >
                            <label> Login </label>

                            {otpState ?
                                <div >
                                    <p>Enter Contact number or Email</p>
                                    <input className='name' type="text"
                                        name="email"
                                        value={email}
                                        onChange={handleInputChange}
                                    />
                                    <button className='nextButton' onClick={handleLogin} >Next</button>
                                </div>
                                :
                                <div>
                                    <p>Enter your One Time password Sent to {email}</p>
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={4}
                                        renderSeparator={<span> </span>}
                                        renderInput={(props) => <input {...props} />}
                                        containerStyle={styles.container}
                                        inputStyle={styles.input}
                                    />
                                    <div className='clearOTP'>
                                        <h4 onClick={() => { setOtp(null) }}>clear Otp</h4>
                                    </div>

                                    <div style={{ opacity: "0.8", border: "1px solid gray", padding: "2px", borderRadius: "10px", alignItems: "center", margin: 0, width: "90%", margin: "0 5%" }}
                                        onClick={backToLogin} >
                                        <p style={{ margin: "5px", fontSize: "13px" }}>Login with Another Email</p>
                                    </div>

                                    <h4 className='nextButton' onClick={handleLogin} >Verify OTP</h4>
                                </div>
                            }


                        </div>

                        <p className='error'>{error}</p>
                    </div>

                    <div className='logo_Block'>
                        <img src={flovation} alt="flovation_Logo" />
                        <p className='versionText' >Version {Version[0].ver}</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default WebLogin






