
import React from 'react'
import "../Styles/Item.css"
import { motion } from "framer-motion"
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { setActive, getActive } from "../Utility/AsyncStorageData"

function Item({ icon, name, path }) {
    
  let navigate = useNavigate();
  var active = getActive();
    const iconName = {
        true: {
            opacity: 1
        },
        false: {
            opacity: 0,
            display: "none",
        }
    }
    return (
        <div className='item'  onClick={()=>{navigate(`${path}`); setActive(JSON.stringify(name));}} 
        style={{backgroundColor: (name == active  ? "#A0DEFF" : "none"), borderRadius: '12px', color: "white"}}
        >
            <div className='icon'>
                {icon}
            </div>
            <motion.span
                variants={iconName}>
                <Link to = {path}>
                    {name}
                </Link>
            </motion.span>
        </div>
    )
}

export default Item