import React from 'react'
import ControllerNavbar from '../../WebAppComponents/ControllerNavbar'
import "../../Styles/DeviceTable.css"
import "../../Styles/UserCard.css"
import Header from "../../WebAppComponents/Header";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { getUserDetails, setCompNum } from "../../Utility/AsyncStorageData";
import Report from '../../Utility/Report'
import Modal from "../../WebAppComponents/Modal";



function ControllerComp() {

    var userDetails = getUserDetails();
    let navigate = useNavigate();

    const [error, setError] = React.useState('');
    const [test, setTest] = React.useState(1);
    const [compDetail, setCompDetail] = React.useState({})
    const [load, setLoad] = React.useState(true);
    const [openExcel, setOpenExcel] = React.useState(false);
    const [reportDeviceId, setReportDeviceId] = React.useState("");
    const [reportname, setReportname] = React.useState("");

    const errortimer = () => {
        setError(undefined)
    }

    const navbarRender = (data) => {
        // console.log('Data received from child:', data);
        setTest(!test)
    };

    const getCompanyStatus = async () => {
        try {
            // console.log("*********** getCompanyStatus ***********")


            let url = process.env.REACT_APP_BASE_URL + "/company/fetchCompanyList";
            let body = {}
            console.log("===============getCompanyStatus======================")

            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        // console.log("getCompanyStatus", response.data.data)
                        setCompDetail(response.data.data)
                        setLoad(false)
                    }

                    else {

                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                        console.log("error in getCompanyWiseSensorList: ", response.data.msg);
                        setLoad(false)
                    }
                }

                else if (response.status == 403) {
                    navigate("/webLogin");
                }
            }).catch(error => {
                if (error.response.status == 403) {
                  navigate("/");
                }
                setLoad(false)
                setError("ERROR")
                setTimeout(() => errortimer(), 2000)
            })
        } catch (error) {
            setLoad(false)
            navigate("/webLogin");
            console.log("error ", error)
            throw error;
        }

    }

    const navigateToDevice = (companyID) => {
        setCompNum(JSON.stringify(companyID))
        navigate("/controllerDevice")
    }

    const openExcelFlie = (item) => {
        setOpenExcel(true)
        setReportname(item.name)
        setReportDeviceId(item.comp_num)
    }

    const receiveDataFromChild = (data) => {
        // Do something with the data received from the child component
        // console.log('Data received from child:', data);
        setOpenExcel(false);
    };


    React.useEffect(() => {
        getCompanyStatus()
    }, []);

    return (
        <div>
            {load != 0 ?
                <div className='load'> <img src="https://tmmapi.9930i.com/loader" alt="Computer man" style={{ height: "150px" }} />
                </div> :

                <div className='main'>

                    <div>
                        <ControllerNavbar />
                    </div>

                    <div className='Admin_menu'>
                        <div>
                            <Header show={3} sendNavbarToParent={navbarRender} />
                        </div>

                        <div className='device_table' style={{ marginTop: "20px" }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ borderRadius: "10px 0 0 10px" }}> Company Id</th>
                                        <th>Company Name</th>
                                        <th>Email</th>
                                        <th>Location</th>
                                        <th>Device</th>
                                        <th style={{ borderRadius: " 0 10px 10px 0" }}  >Report</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {compDetail.length ?
                                        (compDetail.map((item, index) => (
                                            <tr className="device_tr" key={index} >
                                                <td onClick={() => navigateToDevice(item.comp_num)} style={{ borderRadius: "10px 0 0 10px" }}>{item.comp_num}</td>
                                                <td onClick={() => navigateToDevice(item.comp_num)}>{item.name}</td>
                                                <td onClick={() => navigateToDevice(item.comp_num)}>{item.email}</td>
                                                <td onClick={() => navigateToDevice(item.comp_num)}>{item.location}</td>
                                                <td onClick={() => navigateToDevice(item.comp_num)}>{item.device_count}</td>
                                                <td className='action' style={{ borderRadius: " 0 10px 10px 0" }}>
                                                    <i className="fa fa-file-excel-o" onClick={() => openExcelFlie(item)} ></i>
                                                </td>
                                            </tr>
                                        )))
                                        : <></>
                                    }

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div >
            }

            <Modal isOpen={openExcel}>
                <div>
                    <div className='report_download'>
                        <h3>Report Download</h3>
                        <i className="fa-solid fa-xmark" onClick={() => setOpenExcel(false)}></i>
                    </div>
                    <Report show={4} deviceData={reportDeviceId} reportName={reportname} sendDataToParent={receiveDataFromChild} />
                </div>
            </Modal>


        </div>
    )
}

export default ControllerComp
