
import React, { useState } from 'react'
import ControllerNavbar from '../../WebAppComponents/ControllerNavbar'
import "../../Styles/DeviceTable.css"
import "../../Styles/UserCard.css"
import "../../Styles/ControllerDashboard.css"
import axios from "axios";
import Header from "../../WebAppComponents/Header";
import { getUserDetails } from "../../Utility/AsyncStorageData";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Report from '../../Utility/Report'
import Modal from "../../WebAppComponents/Modal";


function ControllerActiveUser() {

    var userDetails = getUserDetails();
    let navigate = useNavigate();

    const [test, setTest] = React.useState(1);
    const [userData, setUserData] = React.useState({});
    const [error, setError] = React.useState('');
    const [fromDate, setFromDate] = useState(moment().subtract(1, 'days').format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));

    const [openExcel, setOpenExcel] = React.useState(false);

    const navbarRender = (data) => {
        // console.log('Data received from child:', data);
        setTest(!test)
    };

    const errortimer = () => {
        setError(undefined)
    }

    const handleUserData = async () => {
        try {

            let url = process.env.REACT_APP_BASE_URL + "/api/loginList";
            let body = {
                from: fromDate,
                to: toDate
            }
            // console.log("************handleUserData************ ",fromDate ,toDate)
            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        setUserData(response.data.data)
                        // console.log("************handle-UserData************ ", response.data.data)

                        setError(undefined)

                    }
                    else if (response.data.statusCode === 1) {
                        setError(undefined);
                    }


                    else {

                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                        console.log(" companyWiseDeviceList error: ", response.data.msg);
                    }

                    // setLoading (false);
                }
                else if (response.status === 403) {
                    navigate("/webLogin");
                }

            }).catch(error => {
                console.log("error: ", error);
                // Alert.alert("ERROR")
                // setError("ERROR")
                // setTimeout(() => errortimer(), 2000)

                setError("Slow or no internet connected. Please check your internet connection.")
                setTimeout(() => handleUserData(), 500)
                return
            })
        } catch (error) {
            console.log("error1 ", error)
            if (error.response.status === 403) {
                navigate("/");
            }
            setError("Slow or no internet connected. Please check your internet")
            setTimeout(() => handleUserData(), 500)
            return
            // throw error;
        }

        // setLoading(false);
    }

    const handleDateFrom = (changedDate) => {
        setFromDate(moment(changedDate).format('YYYY-MM-DD'))
        // console.log("Date From:", moment(changedDate).format('YYYY-MM-DD'));
    }
    const handleDateTo = (changedDate) => {
        setToDate(moment(changedDate).format('YYYY-MM-DD'))
        // console.log("Date to:", moment(changedDate).format('YYYY-MM-DD'));
    }

    const roleId = (role_id) => {
        if (role_id === "A") {
            return "Admin"
        }
        else if (role_id === "M") {
            return "Machine Incharge"
        }
        else if (role_id === "F") {
            return "Floor Manager"
        }
        else
            return role_id
    }

    const downloadUserReport =() => {
        setOpenExcel(true)
        console.log("item")
    }

    
    const receiveDataFromChild = (data) => {
        setOpenExcel(false);
    };

    React.useEffect(() => {
        handleUserData();
    }, [fromDate, toDate]);

    return (
        <div className='main'>

            <div>
                <ControllerNavbar />
            </div>

            <div className='Admin_menu' style={{ marginTop: "20px" }}>
                <Header show={3} sendNavbarToParent={navbarRender} />

                <div className='dateFilter'>
                    <div className='FromDate'>
                        <label>From</label>
                        <DatePicker
                            selected={fromDate}
                            placeholderText='Select From Data'
                            onChange={handleDateFrom}
                            className="custom-datepicker-report"
                            dateFormat="YYYY/MM/dd"
                            maxDate={new Date()}
                        />
                    </div>
                    <div className='FromDate'>
                        <label>To</label>
                        <DatePicker
                            selected={toDate}
                            placeholderText='Select To Data'
                            onChange={handleDateTo}
                            className="custom-datepicker-report"
                            dateFormat="YYYY/MM/dd"
                            maxDate={new Date()}
                            minDate={fromDate}
                        />
                    </div>
                    <div className='reportDownload' onClick={()=> downloadUserReport()}>
                        <i className="fa-solid fa-file-arrow-down"></i>
                    </div>
                </div>

                <div className='device_table'>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ borderRadius: "10px 0 0 10px" }}>Sno</th>
                                <th>ID</th>
                                <th>User</th>
                                <th>Designation</th>
                                <th>Contact</th>
                                <th>Company Name</th>
                                <th style={{ borderRadius: " 0 10px 10px 0" }}  >Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userData.length ?
                                (userData.map((item, index) => (
                                    <tr className="device_tr" key={index}>
                                        <td style={{ borderRadius: "10px 0 0 10px" }}>{index + 1}</td>
                                        <td >{item.user_id}</td>
                                        <td >{item.name}</td>
                                        <td >{roleId(item.role_id)}</td>
                                        <td>{item.contact_email}</td>
                                        <td >{item.comp_name}</td>
                                        <td style={{ borderRadius: " 0 10px 10px 0" }}  > {item.complete_duration}</td>
                                    </tr>
                                )))
                                : <></>
                            }

                        </tbody>
                    </table>


                </div>

                <Modal isOpen={openExcel}>
                    <div>
                        <div className='report_download'>
                            <h3>Report Download</h3>
                            <i className="fa-solid fa-xmark" onClick={() => setOpenExcel(false)}></i>
                        </div>
                        <Report show={7}  sendDataToParent={receiveDataFromChild} />
                    </div>
                </Modal>

                {error ? <p>{error}</p> : <></>}
            </div>
        </div >

    )
}

export default ControllerActiveUser
