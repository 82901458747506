import React from 'react'
import Navbar from './Navbar'
import { getUserDetails, setSensorLog, setSensorOnTime, getDeviceMachines } from "../Utility/AsyncStorageData";
import axios from "axios";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import flovation from "../Assets/logo1.png"

var runtimeid = 0
var statueBarInterval = 19;
var intervalId = 0;

function DeviceMachines() {


    const gb_machine_id = React.useRef("");

    let navigate = useNavigate();
    var userDetails = getUserDetails();
    var deviceWiseMachine = getDeviceMachines();

    const [error, setError] = React.useState('');
    const [graph, setGraph] = React.useState([]);
    const [runningListLog, setRunningListLog] = React.useState({});
    const [runningStatus, setRunningStatus] = React.useState({});
    const [colorWidthData, setColorWidthData] = React.useState([]);



    const errortimer = () => {
        setError(undefined)
    }

    const handleFetchMachineList = async () => {
        try {
            // console.log("*********** handleFetchMachineList ***********")


            let url = process.env.REACT_APP_BASE_URL + '/sensor/getSensorList';
            let body = {
                device_id: deviceWiseMachine.device_id
            }
            // console.log("===============getCompanyWiseSensorList======================", body)

            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        // console.log("getCompanyWiseSensorList======================", response.data.result)

                        const machineIds = response.data.result.map(item => item._id);
                        const machineIdsString = machineIds.map(item => `${item}`).join(', ');

                        // console.log("machineIds===================", machineIdsString);

                        const elements = machineIdsString.split(', ');
                        gb_machine_id.current = elements.map(element => `'${element}'`).join(', ');

                        //subscribeFCMTopic()

                        // console.log("response.data.resultresponse.data.resultresponse.data.result", response.data.result);

                        setGraph(response.data.result)

                        let temp = {};
                        let speed_temp = {};

                        Runtime()

                    }

                    else {

                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                        console.log("error in getCompanyWiseSensorList: ", response.data.msg);
                    }

                    // setLoading(false);
                }


                else if (response.status == 403) {
                    navigate("/");
                }
            }).catch(error => {
                console.log("Error", error.response.status)
                if (error.response.status == 403) {
                    navigate("/");
                  }
                setError("ERROR")
                setTimeout(() => errortimer(), 2000)
                // setLoading(false);
                return
            })
        } catch (error) {
            console.log("error ", error)
            //   setLoading(false);
            throw error;
        }

    }


    const Runtime = async () => {
        try {
            // console.log('*********** getMachineStatus api ***********');

            if (gb_machine_id.current.length != 0) {


                let url = process.env.REACT_APP_BASE_URL + '/sensor/getMachineStatus';
                let body = {
                    doa: moment().format('YYYY-MM-DD'),
                    machine_id: gb_machine_id.current
                };

                // console.log('*********** api hit ***********', body);

                // body.machine_id = elements.map(element => `'${element}'`).join(', ');
                // console.log(" getMachineStatus body", body)
                await axios
                    .post(url, body, {
                        headers: {
                            authorization: `${userDetails.access_token}`,
                        },
                    })
                    .then(response => {
                        if (response.status === 200) {
                            if (response.data.statusCode === 0) {

                                setRunningListLog(response.data)
                                setRunningStatus(response.data.list)

                                statueBarInterval = statueBarInterval + 1;
                                if (statueBarInterval >= 20) {

                                    // console.log("statueBarInterval", statueBarInterval)
                                    statueBarInterval = 0;
                                    dashProgressBar()
                                }

                            }
                            else if (response.data.statusCode === 2) {

                                console.log("getMachineStatus api error", response.data.msg)
                            }
                            else {

                                console.log("getMachineStatus api error", response.data.msg)
                                setError("ERROR")
                                setTimeout(() => errortimer(), 2000);
                            }
                        }
                    })
                    .catch(error => {
                        console.log("Error", error.response.status)
                        if (error.response.status == 403) {
                            navigate("/");
                          }
                        setError('ERROR');
                        setTimeout(() => errortimer(), 2000);
                    });
                // setLoading(false);
            }
            runtimeid = setTimeout(() => Runtime(), 30000)
        } catch (error) {
            console.log('error ', error);
            runtimeid = setTimeout(() => Runtime(), 30000)
            throw error;
        }
        // setLoading(false);
    }


    const dashProgressBar = async () => {
        try {
            // console.log('*********** dashProgressBar api ***********');

            if (gb_machine_id.current.length != 0) {

                let url = process.env.REACT_APP_BASE_URL + '/shift/fetchLogWiseData';
                let a = ""
                for (let key of gb_machine_id.current) {
                    a = a + key.replace("'", "")
                }

                let body = {
                    machine_ids: a
                };

                // console.log(" dashProgressBar body", body)
                await axios
                    .post(url, body, {
                        headers: {
                            authorization: `${userDetails.access_token}`,
                        },
                    })
                    .then(response => {
                        if (response.status === 200) {
                            if (response.data.statusCode === 0) {
                                // setMachineData(response.data.list)
                                // setMachineStates(response.data)
                                setColorWidthData(response.data.result)
                                // console.log(response.data.result)
                            } else {

                                console.log("dashProgressBar api error", response.data.msg)
                                setError(response.data.msg)
                                setTimeout(() => errortimer(), 2000);
                            }
                        }
                        else if (response.status == 403) {
                            // console.log("refreshAccessToken: ");
                            // refreshAccessToken();
                            dashProgressBar();
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 403) {
                            navigate("/");
                          }
                        console.log("ERROR", error)
                        setError('ERROR');
                        setTimeout(() => errortimer(), 2000);
                    });
                // setLoading(false);
            }

        } catch (error) {
            console.log('error ', error);
            setError('ERROR');
            setTimeout(() => errortimer(), 2000);
            // statueBarInterval = setTimeout(() => dashProgressBar(), 30000)
            throw error;
        }
    }

    const machineTime = (mech_id) => {
        // console.log(mech_id, machineData[mech_id])
        if (runningStatus[mech_id].machine_current_status == "D") {
            return (
                <h1 style={{ color: "blue", fontSize: "45px" }} className='clock'>{(runningStatus[mech_id].on_time)}</h1>
            )
        }
        else {
            if (runningStatus[mech_id].efficiency >= 60) {
                return (
                    <h1 style={{ color: "green", fontSize: "45px" }} className='clock'>{(runningStatus[mech_id].on_time)}</h1>
                )
            }
            else if (runningStatus[mech_id].efficiency >= 40) {
                return (
                    <h1 style={{ color: "orange", fontSize: "45px" }} className='clock'>{(runningStatus[mech_id].on_time)}</h1>
                )
            }
            else {
                return (
                    <h1 style={{ color: "red", fontSize: "45px" }} className='clock'>{(runningStatus[mech_id].on_time)}</h1>
                )
            }
        }


    }

    const machineStatus = (mech_id) => {
        // console.log(mech_id, runningStatus[mech_id])
        if (runningStatus[mech_id].machine_current_status == "D") {
            return (
                <div style={{ display: "inline-flex", width: "20%", justifyContent: "flex-end" }}>
                    <h6 style={{
                        backgroundColor: "#5BB450",//BLUE
                        borderRadius: 10,
                        width: 20,
                        height: 15,
                        opacity: 0.5

                    }}> </h6>

                </div>

            )
        }
        else {
            if (runningStatus[mech_id].machine_current_state == "0") {
                return ( //#FA8072"

                    <div style={{ display: "inline-flex", width: "20%", justifyContent: "flex-end" }}>


                        <h6 style={{
                            backgroundColor: "red",//Red
                            borderRadius: 10,
                            width: 15,
                            height: 15

                        }}> </h6>
                    </div>
                )
            }
            else if (runningStatus[mech_id].machine_current_state == "1") {
                return (//"#EE9F27",orange

                    <div style={{ display: "inline-flex", width: "20%", justifyContent: "flex-end" }}>

                        <h6 style={{
                            backgroundColor: "orange",//Red
                            borderRadius: 10,
                            width: 15,
                            height: 15

                        }}> </h6>
                    </div>
                )
            }
            else {
                return ( //"#5BB450", //GREEN 
                    <div style={{ display: "inline-flex", width: "20%", justifyContent: "flex-end" }}>
                        <h6 style={{
                            backgroundColor: "green",//Red
                            borderRadius: 10,
                            width: 15,
                            height: 15
                        }}> </h6>
                    </div>
                )
            }
        }


    }


    const updateOnTimeInSec = () => {
        if (Object.keys(runningStatus).length) {
            setRunningStatus((prevData) => {
                const updatedData = { ...prevData }; // Create a shallow copy of the previous state
                for (let key of graph) {
                    // console.log("key.sensor_data" , updatedData, key.machine_id)
                    if (updatedData[key._id].machine_current_state === '2') {
                        const timeObject = new Date(
                            `2000-01-01T${updatedData[key._id].on_time}`
                        );
                        timeObject.setSeconds(timeObject.getSeconds() + 1);
                        const newTimeString = timeObject.toLocaleTimeString('en-US', {
                            hour12: false,
                        });

                        // Create a new object for the specific item and update the on_time property
                        updatedData[key._id] = {
                            ...updatedData[key._id],
                            on_time: newTimeString,
                        };
                    }
                }
                return updatedData;
            });
        }

    }

    clearInterval(intervalId);
    intervalId = setInterval(() => {
        updateOnTimeInSec();
    }, 1000);


    React.useEffect(() => {
        handleFetchMachineList()
    }, []);
    return (
        <div className='main'>

            <div>
                <Navbar />
            </div>

            <div className='Admin_menu'>
                <div className='Header'>
                    <div style={{ width: "10%" }}>
                        <img src={flovation} alt="flovation_Logo" />
                    </div>
                    <div style={{ width: "70%" }} className='com_name'>
                        <h3>SuperManager</h3>
                        <h4>{userDetails.comp_name}</h4>
                    </div>
                </div>


                <div className='machines'>

                    {graph.length ?
                        (graph.map((item, index) => (
                            <div key={index} className='machine_card'>
                                <div className='row1'>
                                    <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                                        <h4 style={{ textAlign: "center", width: "80%" }}>{item.sensor_name}</h4>

                                        {runningStatus.hasOwnProperty(item._id) ? machineStatus(item._id)
                                            : <h6 style={{
                                                backgroundColor: "white",
                                                width: 30,
                                                borderRadius: 50,
                                            }}> </h6>
                                        }
                                    </div>

                                    <div style={{ marginTop: "5px", display: "flex", width: "100%" }}>
                                        <h6 style={{ width: "30%" }}>{deviceWiseMachine.device_name}</h6>
                                        <div style={{ display: "flex", width: "70%" }}>
                                            {/* <h6 style={{ color: "#5b5656", marginRight: "5px", width: "20%", textAlign: "right" }}>Shift</h6> */}
                                            <h6 style={{ textAlign: "right", width: "100%" }}>Shift : {((Object.keys(runningStatus).length) != 0 && (runningStatus[item._id])) ? (runningStatus[item._id].start_time + " - " + runningStatus[item._id].end_time) : ("NA")}</h6>
                                        </div>
                                    </div>
                                </div>

                                <hr className='line'></hr>

                                <div className='row2'>
                                    <h5 style={{ color: "#5b5656" }}>Run Time</h5>
                                    {((Object.keys(runningStatus).length) != 0 && (runningStatus[item._id])) ? (machineTime(item._id)) : ("--:--:--")}

                                    <h6 style={{ marginTop: "45px" }}>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item._id])) ? (runningStatus[item._id].efficiency) : ("0")}%</h6>
                                </div>

                                <div style={{ display: 'flex', width: '100%', height: '10px', borderRadius: '10px', marginBottom: "3px" }}>
                                    {(Object.keys(colorWidthData).length && (colorWidthData[item._id])) ?
                                        colorWidthData[item._id].map(({ color, width }, index) => (
                                            <div key={index} style={{ width, backgroundColor: color }} ></div> //(index == 0 ? '10px 0 0 10px' : (index == colorWidthData[item.sensor_data._id].length - 1 ? '0px 10px 10px 0px' : '0px')) 

                                        )) :
                                        <div key={index} style={{ width: "100%", backgroundColor: "gray", borderRadius: "10px", height: "10px" }} ></div>}
                                </div>

                                <hr className='line'></hr>

                                <div className='row3' style={{ alignItems: "center" }}>
                                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                        <h6 style={{ color: "#5b5656" }}>Best</h6>
                                        <h6>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item._id])) ? (runningStatus[item._id].best_shift_efficency ?? "0") : ("0")}%</h6>
                                    </div>

                                </div>
                            </div>
                        ))) : (<></>)

                    }

                </div>



            </div>
            <div>
                <p className='error'>{error}</p>
            </div>


        </div >
    )
}

export default DeviceMachines