import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../Styles/TempratureStore.css';
import TempratureNavbar from '../Temperature/TempratureNavbar'

const TempratureStore = () => {
  const [stores, setStores] = useState([]);
  const [cities, setCities] = useState([]);
  const [isStoreFormOpen, setIsStoreFormOpen] = useState(false);
  const [isCityFormOpen, setIsCityFormOpen] = useState(false);
  const [newStore, setNewStore] = useState({});
  const [newCity, setNewCity] = useState({ city_name: '' });
  const [loader, setLoader] = React.useState(true);

  const fetchStoreList = async () => {
    try {
      let body = {}
      let url = process.env.REACT_APP_BASE_URL + '/temprature/fetchStoreList';
      await axios.post(url, body).then((response) => {
        if (response.status === 200 && response.data.statusCode === 0) {
          setStores(response.data.data);
        } else {
          console.log('Error: ', response.data.msg);
        }
        setLoader(false)
      }).catch((error) => {
        setLoader(false)
        console.log('Error: ', error);
      });
    } catch (error) {
      setLoader(false)
      console.log('Error: ', error);
    }
  };

  const fetchCitiesList = async () => {
    try {
      let url = process.env.REACT_APP_BASE_URL + '/temprature/fetchCityList';
      await axios.post(url).then((response) => {
        if (response.status === 200 && response.data.statusCode === 0) {
          let r = []
          for (let key of response.data.data)
            r.push({ id: key.city_id, name: key.city_name })
          setCities(r);
        } else {
          console.log('Error: ', response.data.msg);
        }
      }).catch((error) => {
        console.log('Error: ', error);
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const handleStoreInputChange = (e) => {
    const { name, value } = e.target;
    setNewStore({ ...newStore, [name]: value });
  };

  const handleCityInputChange = (e) => {
    const { name, value } = e.target;
    setNewCity({ ...newCity, [name]: value });
  };

  const handleStoreSubmit = async (e) => {
    e.preventDefault();
    try {
      let url = process.env.REACT_APP_BASE_URL + '/temprature/createNewStore';
      await axios.post(url, newStore).then((response) => {
        if (response.status === 200 && response.data.statusCode === 0) {
          fetchStoreList();
          setIsStoreFormOpen(false);
          setNewStore({});
        } else {
          console.log('Error: ', response.data.msg);
        }
      }).catch((error) => {
        console.log('Error: ', error);
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const handleCitySubmit = async (e) => {
    e.preventDefault();
    try {
      let url = process.env.REACT_APP_BASE_URL + '/temprature/createNewCity';
      await axios.post(url, newCity).then((response) => {
        if (response.status === 200 && response.data.statusCode === 0) {
          fetchCitiesList();
          setIsCityFormOpen(false);
          setNewCity({ city_name: '' });
        } else {
          console.log('Error: ', response.data.msg);
        }
      }).catch((error) => {
        console.log('Error: ', error);
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  useEffect(() => {
    fetchStoreList();
    fetchCitiesList();
  }, []);

  return (


    <div className='main'>

      <div>
        <TempratureNavbar />
      </div>
      {
        loader ?
          <div className='loadtemp' style={{ alignItems: "center" }}> <img src="https://tmmapi.9930i.com/loader" alt="Computer man" style={{ height: "150px" }} />
          </div>
          :
          <div style={{ width: "100%" }}>
            <div className='tableHeading'>
              <h2>Stores</h2>
            </div>
            <div className="temp_table" style={{
              marginTop: "30px",
              alignItems: "flex-start"
            }}>
              <table>
                <thead>
                  <tr>
                    <th style={{ borderRadius: "10px 0 0 10px" }}>S.No.</th>
                    <th>Store Name</th>
                    <th>City Name</th>
                    <th>Store Address</th>
                    <th style={{ borderRadius: "0 10px 10px 0" }}>Email for Report</th>
                  </tr>
                </thead>
                <tbody>
                  {stores.map((store, index) => (
                    <tr key={index}>
                      <td style={{ borderRadius: "10px 0 0 10px" }}>{index + 1}</td>
                      <td>{store.store_name}</td>
                      <td>{store.city_name}</td>
                      <td>{store.store_address}</td>
                      <td style={{ borderRadius: "0 10px 10px 0" }}>{store.email_for_report}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="button-container">
                <button className="add-button1" onClick={() => setIsStoreFormOpen(true)}>Create Store</button>
                <button className="add-button1" onClick={() => setIsCityFormOpen(true)}>Create City</button>
              </div>

              {isStoreFormOpen && (
                <div className="form-overlay">
                  <div className="form-container">
                    <button className="close-button" onClick={() => {setIsStoreFormOpen(false);setNewStore({}) }}>×</button>
                    <form onSubmit={handleStoreSubmit}>
                      <div className="form-group">
                        <label>Store Name</label>
                        <input type="text" name="store_name" value={newStore.store_name} onChange={handleStoreInputChange} required />
                      </div>
                      <div className="form-group">
                        <label>City</label>
                        <select name="city_id" value={newStore.city_id} onChange={handleStoreInputChange} required>
                          <option value="" hidden>Select City</option>
                          {cities.map((city) => (
                            <option key={city.id} value={city.id}>{city.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Store Address</label>
                        <input type="text" name="store_address" value={newStore.store_address} onChange={handleStoreInputChange} required />
                      </div>
                      <div className="form-group">
                        <label>Email for Report</label>
                        <input type="email" name="email_for_report" value={newStore.email_for_report} onChange={handleStoreInputChange} required />
                      </div>
                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
              )}

              {isCityFormOpen && (
                <div className="form-overlay">
                  <div className="form-container">
                    <button className="close-button" onClick={() => {setIsCityFormOpen(false);setNewCity({ city_name: '' })}}>×</button>
                    <form onSubmit={handleCitySubmit}>
                      <div className="form-group">
                        <label>City Name:</label>
                        <input type="text" name="city_name" value={newCity.city_name} onChange={handleCityInputChange} required />
                      </div>
                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
      }
    </div>
  );
};

export default TempratureStore;
