import React from "react";
import "../Styles/TempratureDashboard.css";
import axios from "axios";
import TempratureNavbar from '../Temperature/TempratureNavbar'
import { useNavigate } from 'react-router-dom';

const TempratureDashboard = () => {
  const [data, setData] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const navigate = useNavigate();

  const handleCardClick = (city_id, flag) => {
    navigate("/Devicestats", { state: { city_id, flag } })
  };

  const fetchStats = async () => {
    try {
      let url =
        process.env.REACT_APP_BASE_URL + "/temprature/fetchDashboardData";
      let body = {
        user_id: 1,
      };
      await axios
        .post(url, body)
        .then((response) => {
          if (response.status === 200) {
            // console.log("user token ", response.data.result)
            if (response.data.statusCode === 0) {
              ///for success
              setData(response.data.stats)
            } else {
              setLoader(false)
              return;
            }
            
            setLoader(false)
          } else if (response.status === 401) {
            // setError(response.data.msg)
            // setTimeout(() => errortimer(), 2000)
            // navigate("/");
            
            setLoader(false)
            return;
          } else {
            
            setLoader(false)
            console.log("error in userLogin: ", response.data.msg);
            // setError(response.data.msg)
            // setTimeout(() => errortimer(), 2000)
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          // if (error.response.status == 403) {
          //     navigate("/");
          // }
          // setError("Server Error")
          // setTimeout(() => errortimer(), 2000)
          return;
        });
    } catch (error) {
      console.log("error1 ", error);
      throw error;
    }
  };
  React.useEffect(() => {
    fetchStats();
  }, []);

  return (

    <div className='main'>

      <div>
        <TempratureNavbar />
      </div>
      {
        loader ?
          <div className='loadtemp' style={{ alignItems: "center" }}> <img src="https://tmmapi.9930i.com/loader" alt="Computer man" style={{ height: "150px" }} />
          </div>
          :
          <div className="dashboard1">
            {data.map((item, index) => (
              <div key={index} className="dashboardBlock" style={{ width: "90%" }}>
                <div className="cardHeader">
                  <h2>{item.city_name}</h2>
                </div>

                <div className="temprow1">
                  <div className="card" onClick={() => handleCardClick(item.city_id, 0)}>
                    <div className="card-body">
                      <p>No. Of Stores</p>
                      <h3> {item.store_count}</h3>
                    </div>
                  </div>
                  <div className="card" onClick={() => handleCardClick(item.city_id, 0)}>
                    <div className="card-body">
                      <p>No. Of Devices</p>
                      <h3>{item.device_count}</h3>
                    </div>
                  </div>
                  <div className="card" onClick={() => handleCardClick(item.city_id, 0)}>
                    <div className="card-body">
                      <p>Active Devices</p>
                      <h3>{item.stats_status_count}</h3>
                    </div>
                  </div>
                  <div className="card" onClick={() => handleCardClick(item.city_id, 1)}>
                    <div className="card-body">
                      <p>Alarmed Devices</p>
                      <h3>{item.stats_alarmed_count}</h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
      }
    </div>
  );
};

export default TempratureDashboard;
