import React, { useEffect } from 'react'
import "../Styles/CompanyForm.css"
import { getUserDetails, setUserDetails } from "../Utility/AsyncStorageData";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import flovation from "../Assets/Logo2.png"
import picture from "../Assets/max1.png"

function CompanyForm() {

  let navigate = useNavigate();

  const [slideIndex, setSlideIndex] = React.useState(1);
  const [millis, setMillis] = React.useState(4000);
  const [interval, setIntervalValue] = React.useState();


  const [error, setError] = React.useState('');
  const [machineData, setMachineData] = React.useState({
    comp_name: "",
    comp_Loction: "",
    comp_pin: "",
    comp_gst: "",
    email: ""
  })


  var userDetails = getUserDetails();

  const handleChange = (fieldName, value) => {
    setMachineData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };


  const submitCompForm = async () => {


    if (machineData.comp_name.length == 0) {
      setError("Enter All Fields")
      setTimeout(() => errortimer(), 2000)
      return
    }


    try {
      let url = process.env.REACT_APP_BASE_URL + "/company/registerCompany";
      let body = {
        comp_num: userDetails.comp_num,
        name: machineData.comp_name,
        location: machineData.comp_Loction,
        pincode: machineData.comp_pin,
        gst: machineData.comp_gst,
        email: machineData.email
      }
      // console.log("**********registerCompany**************** ", body);

      var data = userDetails
            data.comp_name = machineData.comp_name

            setUserDetails(JSON.stringify(data));
      await axios.post(url, body, {
        headers: {
          authorization: `${userDetails.access_token}`,
        },
      }).then(response => {

        if (response.status === 200) {

          if (response.data.statusCode === 0) {

            
            // console.log("response: ", response.data, userDetailsString);


            navigate("/webAdmin");
            setError(response.data.msg).
              setTimeout(() => errortimer(), 2000)
            return
          }
          else {
            console.log("registerCompany error", response.data.msg);
            setError(response.data.msg)
            setTimeout(() => errortimer(), 2000)
            return
          }
        }
      }).catch(error => {
        console.log("error: ", error);
        if (error.response.status == 403) {
            navigate("/");
          }
        setError("ERROR")
        setTimeout(() => errortimer(), 2000)
        return
      })

    } catch (error) {
      console.log("error ", error)
      throw error;
    }


  };

  const errortimer = () => {
    setError(undefined)
  }

  // store your index and time in state

  // use effect to manage your interval
  useEffect(() => {
    startSlides();
    return pauseSlides;
  }, []);

  // change slides in effect
  useEffect(() => {
    showSlide();
  }, [slideIndex]);

  function nextSlide() {
    setSlideIndex((idx) => idx + 1); // use state set function
  }

  function currentSlide(idx) {
    setSlideIndex(idx);
  }

  function pauseSlides() {
    clearInterval(interval);
  }

  function startSlides() {
    nextSlide();
    const interval = setInterval(nextSlide, millis);
    setIntervalValue(interval);
  }

  function showSlide() {
    let slides = document.getElementsByClassName("logo_img");

    for (let i = 0; i < slides.length; i++) slides[i].style.display = "none";

    if (slideIndex > slides.length) {
      setSlideIndex(1);
    } else if (slideIndex < 1) {
      setSlideIndex(slides.length);
    } else {
      // console.log(slideIndex);
      slides[slideIndex - 1].style.display = "flex";
    }
  }

  return (
    <div className='maincomp'>
      <div className='secondblock'>
        <div className="logo_img">
          <img src={flovation} alt="Logo" width="100" />
          <h3>What Get Measured, Gets Managed</h3>
        </div>

        <div className='logo_img'>
          <img src={picture} alt="scrollingImage 1" width={300}></img>
          <h3>Empowering MSMEs to Industries</h3>
        </div>


        <div className='comp_block'>
          <h4 style={{ textAlign: "center", color: "#252B42", margin: "0px 0px 10px 0" }}>Register Company</h4>
          <div className='fields1'>

            <div>
              <label>Registered Email</label>
              <input className='inputField'
                type="email"
                name="email"
                value={machineData.email}
                onChange={(e) => handleChange('email', e.target.value)}
              />
            </div>
            <div>
              <label>Comapany Name</label>
              <input className='inputField'
                type="text"
                name="comp_name"
                value={machineData.comp_name}
                onChange={(e) => handleChange('comp_name', e.target.value)}
              />
            </div>

            <div>
              <label>Company Location</label>
              <input className='inputField'
                type="text"
                name="comp_Loction"
                value={machineData.comp_Loction}
                onChange={(e) => handleChange('comp_Loction', e.target.value)}
              />
            </div>

            <div>
              <label>Pin Code</label>
              <input className='inputField'
                type="text"
                name="comp_pin"
                value={machineData.comp_pin}
                onChange={(e) => handleChange('comp_pin', e.target.value)}
              />
            </div>

            <div>
              <label>Company GST Number</label>
              <input className='inputField'
                type="text"
                name="comp_gst"
                value={machineData.comp_gst}
                onChange={(e) => handleChange('comp_gst', e.target.value)}
              />
            </div>


            <button className='comp_btn' onClick={submitCompForm}>Submit</button>
          </div>
        </div>
      </div>
      <div className='Skip'>
        <h4 onClick={() => {
          navigate("/webAdmin")
        }}>Skip</h4>
      </div>
      <div>
        <p className='error'>{error}</p>
      </div>
    </div>

  )
}

export default CompanyForm
