import React, { useState, useEffect, useRef } from "react";
import { getUserDetails, setSensorLog, setSensorOnTime, setStats } from "../Utility/AsyncStorageData";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import flovation from "../Assets/logo1.png"
import "../Styles/Temperature.css"
import moment from 'moment';
import noData from "../Assets/nodata.png"


var intervalId = 0;

function TempDashboard() {
  let navigate = useNavigate();

  const [load, setLoad] = React.useState(true);
  const [senseData, setSenseData] = useState([])
  const [headerData, setHeaderData] = useState({ active: "0", inactive: "0", min: "0", max: "0" })
  const flag = useRef(0);

  const getTemperature = async () => {

    try {

      let url = process.env.REACT_APP_BASE_URL + "/temprature/tempratureDeviceData";
      let body = {
        date: moment().format("YYYY-MM-DD"),
        flag: flag.current
      }
      console.log("************getTemperature************ ")
      await axios.post(url, body, {
        headers: {
          'authorization': ``
        },
      }).then(response => {

        if (response.status === 200) {

          if (response.data.statusCode === 0) {

            console.log(" getTemperature : ", response.data);
            setSenseData(response.data.data)
            const { active, inactive, min, max } = response.data;
            setHeaderData({ active, inactive, min, max });
            setLoad(false)

          }
          else {
            // console.log(" getTemperature error: ", response.data.msg);
            setSenseData([])
            setLoad(false)
          }
        }

        else if (response.status == 403) {
          navigate("/");
        }
      }).catch(error => {
        console.log("error: ", error);
        if (error.response.status == 403) {
          navigate("/");
        }
        return
      })
    } catch (error) {
      console.log("error1 ", error)
      return
      // throw error;
    }
  }

  const detailsOfTemp = (item) => {
    console.log("navigate to detail page")
    navigate("/tempDetailsLog", { state: { item } });
  }

  clearInterval(intervalId);
  intervalId = setInterval(() => {
    getTemperature();
  }, 60000);

  useEffect(() => {
    getTemperature();
  }, []);

  return (
    <>
      {load !== false ?
        <div className='load'> <img src="https://tmmapi.9930i.com/loader" alt="Computer man" style={{ height: "150px" }} />
        </div> :
        <div style={{ height: "100vh", overflowY: "scroll", scrollbarWidth: "none", overflowX: "hidden" }}>
          <div className="Header_temp">
            <div className="Header_temp">
              <div className="logo">
                <img src={flovation} alt="flovation_Logo" />
              </div>
              <div style={{ width: "60%" }} className='com_name'>
                <h3 style={{ margin: "0" }}>SuperManager</h3>
              </div>
            </div>

            <div className="stats">
              <h2 style={{ backgroundColor: "rgb(132, 132, 239)", border: "2px solid blue" }} onClick={() => { flag.current = 0; getTemperature(); setLoad(true); }}>{headerData.active + headerData.inactive}</h2>
              <h2 style={{ backgroundColor: "lightGreen", border: "2px solid green" }} onClick={() => { flag.current = 1; getTemperature(); setLoad(true); }}>{headerData.active}</h2>
              <h2 style={{ backgroundColor: "rgb(242, 187, 84)", border: "2px solid orange" }} onClick={() => { flag.current = 2; getTemperature(); setLoad(true); }}>{headerData.inactive}</h2>
              <h2 style={{ backgroundColor: "rgb(245, 143, 143)", border: "2px solid red" }} onClick={() => { flag.current = 4; getTemperature(); setLoad(true); }} >{headerData.min}</h2>
              <h2 style={{ backgroundColor: "rgb(238, 238, 148)", border: "2px solid yellow" }} onClick={() => { flag.current = 3; getTemperature(); setLoad(true); }}>{headerData.max}</h2>
            </div>

          </div>


          <div className="block_temp">

            {senseData.length ?
              (senseData.map((item, index) => (
                //  
                <div className="temp_box" key={index} onClick={() => detailsOfTemp(item)}>
                  <div className="colm_1">
                    <h5>{item.device_name}</h5>
                    <h5 style={{ margin: "0", backgroundColor: (item.status === "C" ? "green" : "red"), width: "15px", height: "15px", borderRadius: "50%" }}></h5>
                  </div>
                  <div className="temp_humi">
                    <div className="block-1">
                      <h5 className="col_2">Temperature</h5>
                      <h1 className="col_3" style={{ color: (item.status === "C" ? "green" : "red") }} >{item.current_temp}°C</h1>
                    </div>
                    <div className="block-2">
                      <h5 className="col_2">Humidity</h5>
                      <h2 className="col_3" style={{ color: (item.status === "C" ? "green" : "red") }} >{Math.floor(item.current_humidity)}%</h2>
                    </div>
                  </div>
                  <div className="col_4" style={{ justifyContent: "space-evenly" }} >
                    <h5 style={{ color: "red" }}>min</h5>
                    <h5 style={{ color: "orange" }}>max</h5>
                  </div>
                  
                  <hr style={{margin: "0", border:" 1px solid gray"}}></hr>

                  <div className="col_5" style={{ justifyContent: "space-evenly" }}>
                    <div style={{ width: "40%" }}>
                      <h5 style={{ color: "red" }}>{item.min_temp}°C</h5>
                      <h5 style={{ color: "red" }}>{item.min_humidity}%</h5>
                    </div>
                    <h5>Today</h5>
                    <div style={{ width: "40%" }}>
                      <h5 style={{ color: "orange" }}>{item.max_temp}°C</h5>
                      <h5 style={{ color: "orange" }}>{item.max_humidity}%</h5>
                    </div>
                  </div>

                  <hr style={{margin: "0", border:" 1px dashed gray"}}></hr>

                  <div className="col_6" style={{ justifyContent: "space-evenly" }}>
                    <div style={{ width: "40%" }}>
                      <h5 style={{ color: "red" }}>{"<"}{item.min_thershold}°C</h5>
                      <h5 style={{ color: "red" }}>{"<"}{item.min_thershold_humidity}%</h5>
                    </div>
                    <h5>Alert @</h5>
                    <div style={{ width: "40%" }}>
                      <h5 style={{ color: "orange" }}>{">"}{item.max_thershold}°C</h5>
                      <h5 style={{ color: "orange" }}>{">"}{item.max_thershold_humidity}%</h5>
                    </div>
                  </div>

                </div>
              ))) :
              <div>
                <div className="noData">
                  <img src={noData} alt="No data found" />
                </div>
              </div>
            }
          </div>


        </div>
      }
    </>
  )
}

export default TempDashboard