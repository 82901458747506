import React from 'react'
import ControllerNavbar from '../../WebAppComponents/ControllerNavbar'
import "../../Styles/DeviceTable.css"
import "../../Styles/UserCard.css"
import "../../Styles/ControllerDashboard.css"
import WebMap from '../WebMap'
import axios from "axios";
import Header from "../../WebAppComponents/Header";
import { getUserDetails } from "../../Utility/AsyncStorageData";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
// import GoogleMapReact from 'google-map-react';



const CustomMarker = ({ text }) => (
  <div style={{
    position: 'relative',
    width: '30px',
    height: '30px',
    backgroundColor: 'transparent',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)'
  }}>
    <img
      src="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
      alt="Marker"
      style={{ width: '100%', height: '100%' }}
    />
    <span style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      fontSize: '12px',
      fontWeight: 'bold'
    }}>
      {text}
    </span>
  </div>
);


function ControllerDashboard() {

  const defaultProps = {
    center: {
      lat: 22.7657123, // default latitude
      lng: 75.8445059,
    },
    zoom: 11
  };

  const markers = [
    { lat: 22.6201925, lng: 75.6698644 },
    { lat: 22.7352389, lng: 75.7770795 },
    { lat: 22.7682468, lng: 75.8421117 },
    // Add more markers here
  ];



  var userDetails = getUserDetails();
  let navigate = useNavigate();

  console.log('\\\\\\\\\\\\\\\\\ControllerDashboard\\\\\\\\\\\\\\\\\\\\\\\\\\')
  const [error, setError] = React.useState('');
  const [cords, setCords] = React.useState([]);
  const [dashboardData, setDashboardData] = React.useState({});
  const [test, setTest] = React.useState(1);


  const companyWiseDeviceList = async () => {
    try {
      // console.log("*********** companyWiseDeviceList ***********")


      let url = process.env.REACT_APP_BASE_URL + "/device/companyWiseDeviceList";
      let body = {}
      // console.log("===============companyWiseDeviceList======================")

      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            let i = 0
            const markers = response.data.data.map(obj => {
              if (obj.cords != undefined && obj.cords.length) {
                const [lat, lng] = obj.cords.split(',').map(coord => parseFloat(coord));
                return { id: i++, lat, lng };
              }

            });

            const filteredMarkers = markers.filter(marker => marker !== undefined);
            console.log("companyWiseDeviceList", filteredMarkers);
            setCords(filteredMarkers)
          }

          else {

            setError(response.data.msg)
            setTimeout(() => errortimer(), 2000)
            console.log("error in getCompanyWiseSensorList: ", response.data.msg);
          }
        }

        else if (response.status == 403) {
          navigate("/");
        }
      }).catch(error => {
        console.log("Error", error.response.status)
        if (error.response.status == 403) {
          navigate("/");
        }
        setError("ERROR")
        setTimeout(() => errortimer(), 2000)
      })
    } catch (error) {
      console.log("error ", error)
      throw error;
    }

  }


  const DashboardData = async () => {
    try {
      // console.log("*********** DashboardData ***********")


      let url = process.env.REACT_APP_BASE_URL + "/device/dashboard";
      let body = {
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD")
      }
      // console.log("===============DashboardData======================", body)

      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            // console.log("/device/dashboard", response.data.data)
            setDashboardData(response.data.data)
          }

          else {

            setError(response.data.msg)
            setTimeout(() => errortimer(), 2000)
            console.log("error in getCompanyWiseSensorList: ", response.data.msg);
          }
        }
      }).catch(error => {
        console.log("Error", error.response.status)
        if (error.response.status == 403) {
          navigate("/");
        }
        setError("ERROR")
        setTimeout(() => errortimer(), 2000)
      })
    } catch (error) {
      console.log("error ", error)
      throw error;
    }

  }

  const errortimer = () => {
    setError(undefined)
  }
  React.useEffect(() => {
    companyWiseDeviceList()
    DashboardData()
  }, []);

  const navbarRender = (data) => {
    // console.log('Data received from child:', data);
    setTest(!test)
  };





  return (
    <div className='main'>

      <div>
        <ControllerNavbar />
      </div>

      <div className='Admin_menu' style={{ overflowY: "scroll", marginTop: "10px", height: "100vh", scrollbarWidth: "none" }}>
        <div>
          <Header show={3} sendNavbarToParent={navbarRender} />
        </div>
        <div className='block'>
          <div className='stats_Card' onClick={() => { navigate("/controllerComp"); }}>
            <div className='icon_block'>
              <i className="fa-solid fa-industry" style={{ backgroundColor: "orange" }}></i>
            </div>
            <div className='value'>
              <h4>Companies</h4>
              <h2>{dashboardData.company_count}</h2>
            </div>
          </div>

          <div className='stats_Card' onClick={() => { navigate("/controllerDevice"); }}>
            <div className='icon_block'>
              <i class="fa-solid fa-mobile-button" style={{ backgroundColor: "green" }}></i>
            </div>
            <div className='value'>
              <h4>Devices</h4>
              <h2>{dashboardData.device_count}</h2>
            </div>
          </div>


          <div className='stats_Card' onClick={() => { navigate("/controllerMachine"); }}>
            <div className='icon_block'>
              <i class="fa-solid fa-cash-register" style={{ backgroundColor: "red" }}></i>
            </div>
            <div className='value'>
              <h4>Machine</h4>
              <h2>{dashboardData.machine_count}</h2>
            </div>
          </div>

          <div className='stats_Card' onClick={() => { navigate("/controllerUser"); }}>
            <div className='icon_block'>
              <i class="fa-solid fa-user" style={{ backgroundColor: "blue" }}></i>
            </div>
            <div className='value'>
              <h4>Users</h4>
              <h2>{dashboardData.user_count}</h2>
            </div>
          </div>

          <div className='stats_Card' onClick={() => { navigate("/controllerActiveUser"); }}>
            <div className='icon_block'>
              <i class="fa-solid fa-user-check" style={{ backgroundColor: "pink" }}></i>
            </div>
            <div className='value'>
              <h4>Login User</h4>
              <h2>{dashboardData.login_count}</h2>
            </div>
          </div>

        </div>
        <div>
          <WebMap latLong={cords} />
        </div>
{/* 
        <div style={{ height: '60vh', borderRadius: "10px", alignItems: "center", margin: "30px 10vh" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBsNb2DlLCll3rqWQFOhBQQf4_miqmmuy4' }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            {markers.map((marker, index) => (
              <CustomMarker
                key={index}
                lat={markers.lat}
                lng={markers.lng}
              />
            ))}
          </GoogleMapReact>
        </div> */}


      </div>
    </div >
  )
}

export default ControllerDashboard
