import React, { useState, useRef, useEffect } from "react";
import "../Styles/WebAdmin.css"
import Navbar from "./Navbar";
import { getUserDetails, setSensorLog, setSensorOnTime, setStats } from "../Utility/AsyncStorageData";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Header from "./Header";

var runtimeid = 0
var statueBarInterval = 19;
var intervalId = 0;

function WebAdmin() {

  var userDetails = getUserDetails();

  // console.log("**************WebAdmin*****************", userDetails)
  let navigate = useNavigate();

  const gb_machine_id = useRef("");
  const [error, setError] = React.useState('');
  const [graph, setGraph] = React.useState([]);
  const [runningListLog, setRunningListLog] = React.useState({});
  const [runningStatus, setRunningStatus] = React.useState({});
  const [colorWidthData, setColorWidthData] = React.useState([]);



  const handleFetchMachineList = async () => {
    try {
      // console.log("*********** handleFetchMachineList ***********")


      let url = process.env.REACT_APP_BASE_URL + "/sensor/getCompanyWiseSensorList";
      let body = {
        comp_num: userDetails.comp_num
      }
      // console.log("===============getCompanyWiseSensorList======================", body)

      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            // console.log("getCompanyWiseSensorList======================", response.data.result)

            const machineIds = response.data.result.map(item => item.machine_id);
            const machineIdsString = machineIds.map(item => `${item}`).join(', ');

            // console.log("machineIds===================", machineIdsString);

            const elements = machineIdsString.split(', ');
            gb_machine_id.current = elements.map(element => `'${element}'`).join(', ');

            //subscribeFCMTopic()

            // console.log("response.data.resultresponse.data.resultresponse.data.result", response.data.result);

            setGraph(response.data.result)

            let temp = {};
            let speed_temp = {};

            Runtime()

          }

          else {

            setError(response.data.msg)
            setTimeout(() => errortimer(), 2000)
            console.log("error in getCompanyWiseSensorList: ", response.data.msg);
          }

          // setLoading(false);
        }

        else if (response.status == 403) {
          navigate("/");
        }

      }).catch(error => {
        console.log("error: ", error);
        if (error.response.status == 403) {
          navigate("/");
        }
        setError("ERROR")
        setTimeout(() => errortimer(), 2000)
        //         setLoading(false);
        //         return
      })
    } catch (error) {
      console.log("error ", error)
      //     setLoading(false);
      //     throw error;
    }

  }


  const Runtime = async () => {
    try {
      // console.log('*********** getMachineStatus api ***********');

      if (gb_machine_id.current.length != 0) {

        // console.log('*********** api hit ***********', d);

        let url = process.env.REACT_APP_BASE_URL + '/sensor/getMachineStatus';
        let body = {
          // machineState: machineState,
          machine_id: gb_machine_id.current
        };

        // body.machine_id = elements.map(element => `'${element}'`).join(', ');
        // console.log(" getMachineStatus body", body)
        await axios
          .post(url, body, {
            headers: {
              authorization: `${userDetails.access_token}`,
            },
          })
          .then(response => {
            if (response.status === 200) {
              if (response.data.statusCode === 0) {

                setRunningListLog(response.data)
                setStats(JSON.stringify(response.data))

                setRunningStatus(response.data.list)


                // console.log("statueBarInterval1", statueBarInterval, response.data)
                statueBarInterval = statueBarInterval + 1;
                if (statueBarInterval >= 20) {

                  // console.log("statueBarInterval", statueBarInterval)
                  statueBarInterval = 0;
                  dashProgressBar()
                }

              }
              else if (response.data.statusCode === 2) {

                console.log("getMachineStatus api error", response.data.msg)
              }
              else {

                console.log("getMachineStatus api error", response.data.msg)
                setError("ERROR")
                setTimeout(() => errortimer(), 2000);
              }
            }
          })
          .catch(error => {
            // Alert.alert("ERROR")
            console.log("ERROR", error)
            if (error.response.status == 403) {
              navigate("/");
            }
            setError('ERROR');
            setTimeout(() => errortimer(), 2000);
          });
        // setLoading(false);
      }
      runtimeid = setTimeout(() => Runtime(), 30000)
    } catch (error) {
      console.log('error ', error);
      runtimeid = setTimeout(() => Runtime(), 30000)
      throw error;
    }
    // setLoading(false);
  }

  const dashProgressBar = async () => {
    try {
      // console.log('*********** dashProgressBar api ***********');

      if (gb_machine_id.current.length != 0) {

        let url = process.env.REACT_APP_BASE_URL + '/shift/fetchLogWiseData';
        let a = ""
        for (let key of gb_machine_id.current) {
          a = a + key.replace("'", "")
        }

        let body = {
          machine_ids: a
        };

        // console.log(" dashProgressBar body", body)
        await axios
          .post(url, body, {
            headers: {
              authorization: `${userDetails.access_token}`,
            },
          })
          .then(response => {
            if (response.status === 200) {
              if (response.data.statusCode === 0) {
                // setMachineData(response.data.list)
                // setMachineStates(response.data)
                setColorWidthData(response.data.result)
                // console.log(response.data.result)
              } else {

                console.log("dashProgressBar api error", response.data.msg)
                setError(response.data.msg)
                setTimeout(() => errortimer(), 2000);
              }
            }
            else if (response.status == 403) {
              // console.log("refreshAccessToken: ");
              // refreshAccessToken();
              dashProgressBar();
            }
          })
          .catch(error => {
            if (error.response.status == 403) {
              navigate("/");
            }
            console.log("ERROR", error)
            setError('ERROR');
            setTimeout(() => errortimer(), 2000);
          });
        // setLoading(false);
      }

    } catch (error) {
      console.log('error ', error);
      setError('ERROR');
      setTimeout(() => errortimer(), 2000);
      // statueBarInterval = setTimeout(() => dashProgressBar(), 30000)
      throw error;
    }
  }

  const borderRadius = (serialNo, machineId) => {
    // console.log(serialNo, machineId ,colorWidthData[machineId].length )
    if (colorWidthData[machineId].length == 1) {
      return "10px"
    }
    else if (serialNo == colorWidthData[machineId].length - 1) {
      // console.log(colorWidthData[machineId])
      return " 0 10px 10px 0 "
    }
    else if (serialNo == 0) {
      return " 10px 0 0 10px"
    }


  }

  const machineTime = (mech_id, data) => {
    if (runningStatus[mech_id].machine_current_status == "D") {
      return (
        <h1 style={{ color: "blue", fontSize: "45px" }} className='clock'>{(runningStatus[mech_id].on_time)}</h1>
      )
    }
    else {
      if (runningStatus[mech_id].efficiency >= data.Y) {
        return (
          <h1 style={{ color: "green", fontSize: "45px" }} className='clock'>{(runningStatus[mech_id].on_time)}</h1>
        )
      }
      else if (runningStatus[mech_id].efficiency >= data.R) {
        return (
          <h1 style={{ color: "orange", fontSize: "45px" }} className='clock'>{(runningStatus[mech_id].on_time)}</h1>
        )
      }
      else {
        return (
          <h1 style={{ color: "red", fontSize: "45px" }} className='clock'>{(runningStatus[mech_id].on_time)}</h1>
        )
      }
    }
  }

  const updateOnTimeInSec = () => {
    if (Object.keys(runningStatus).length) {
      setRunningStatus((prevData) => {
        const updatedData = { ...prevData }; // Create a shallow copy of the previous state
        for (let key of graph) {
          // console.log("key.sensor_data" , updatedData, key.machine_id)
          if (updatedData[key.machine_id].machine_current_state === '2') {
            const timeObject = new Date(
              `2000-01-01T${updatedData[key.machine_id].on_time}`
            );
            timeObject.setSeconds(timeObject.getSeconds() + 1);
            const newTimeString = timeObject.toLocaleTimeString('en-US', {
              hour12: false,
            });

            // Create a new object for the specific item and update the on_time property
            updatedData[key.machine_id] = {
              ...updatedData[key.machine_id],
              on_time: newTimeString,
            };
          }
        }
        return updatedData;
      });
    }

  }


  const machineStatus = (mech_id) => {
    // console.log(mech_id, runningStatus[mech_id])
    if (runningStatus[mech_id].machine_current_status == "D") {
      return (
        <div style={{ display: "inline-flex", width: "20%", justifyContent: "flex-end" }}>
          <h6 style={{
            backgroundColor: "blue",//BLUE
            borderRadius: 10,
            width: 15,
            height: 15,

          }}> </h6>
        </div>

      )
    }
    else {
      if (runningStatus[mech_id].machine_current_state == "0") {
        return ( //#FA8072"

          <div style={{ display: "inline-flex", width: "20%", justifyContent: "flex-end" }}>
            <h6 style={{
              backgroundColor: "red",//Red
              borderRadius: 10,
              width: 15,
              height: 15

            }}> </h6>
          </div>
        )
      }
      else if (runningStatus[mech_id].machine_current_state == "1") {
        return (//"#EE9F27",orange

          <div style={{ display: "inline-flex", width: "20%", justifyContent: "flex-end" }}>

            <h6 style={{
              backgroundColor: "orange",//Red
              borderRadius: 10,
              width: 15,
              height: 15

            }}> </h6>
          </div>
        )
      }
      else {
        return ( //"#5BB450", //GREEN 
          <div style={{ display: "inline-flex", width: "20%", justifyContent: "flex-end" }}>
            <h6 style={{
              backgroundColor: "green",//orange
              borderRadius: 10,
              width: 15,
              height: 15,

            }}> </h6>
          </div>
        )
      }
    }


  }

  const NavigateToLogs = (item) => {
    // console.log("NavigateToLogs", item)
    setSensorLog(JSON.stringify(item))

    let temp = {
      on_time: runningStatus[item.machine_id].on_time,
      efficiency: runningStatus[item.machine_id].efficiency,
      mcs: runningStatus[item.machine_id].machine_current_state
    }

    setSensorOnTime(JSON.stringify(temp))
    navigate("/machineLogs");

  }



  clearInterval(intervalId);
  intervalId = setInterval(() => {
    updateOnTimeInSec();
  }, 1000);

  const errortimer = () => {
    setError(undefined)
  }


  useEffect(() => {
    handleFetchMachineList()
    return () => {
      clearInterval(runtimeid);
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);


  return (
    <div className='main'>

      <div>
        <Navbar />
      </div>

      <div className='Admin_menu'>
        <div className='TvDashboard'>

          <Header show={0} />

          <div className='com_name_mobile'>
            <h3>SuperManager</h3>
          </div>

          <div className='machines'>

            {graph.length ?
              (graph.map((item, index) => (
                <div key={index} className='machine_card' onClick={() => NavigateToLogs(item)}>
                  <div className='row1'>
                    <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                      <h4 style={{ textAlign: "center", width: "80%" }}>{item.sensor_name}</h4>

                      {runningStatus.hasOwnProperty(item.machine_id) ? machineStatus(item.machine_id)
                        : <h6 style={{
                          backgroundColor: "white",
                          width: 30,
                          borderRadius: 50,
                        }}> </h6>
                      }
                    </div>
                  </div>

                  <div style={{ marginTop: "5px", display: "flex", width: "100%" }}>
                    <h6 style={{ width: "30%" }}>{item.device_name}</h6>
                    <div style={{ display: "flex", width: "70%" }}>
                      {/* <h6 style={{ color: "#5b5656", marginRight: "5px", width: "20%", textAlign: "right" }}>Shift</h6> */}
                      <h6 style={{ textAlign: "right", width: "100%" }}>Shift : {((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (runningStatus[item.machine_id].start_time + " - " + runningStatus[item.machine_id].end_time) : ("NA")}</h6>
                    </div>
                  </div>

                  <hr className='line'></hr>

                  <h5 style={{ color: "#5b5656", margin: "0px" }}>Run Time</h5>
                  <div style={{ width: "100%" }}>
                    {/* <h1 style={{ color: "red", fontSize: "45px" }}>00:00:00</h1> */}
                    {((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id]))
                      ?
                      <div style={{
                        display: (item.counter_flag === "Y" ? "inline-flex" : "flex"),
                        justifyContent: (item.counter_flag === "Y" ? "space-around" : "center"),
                        width: "100%",
                        alignItems: "baseline"
                      }}>
                        {machineTime(item.machine_id, item.alarm_color)}
                        <h1 style={{ display: (item.counter_flag == "Y" ? "block" : "none") }}>{runningStatus[item.machine_id].sensor_count}</h1>

                      </div>
                      : ("--:--:--")}

                    {/* {((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (runningStatus[item.machine_id].sensor_count) : ("0")}</h6>} */}
                  </div>
                  <h6 style={{ margin: "0px", textAlign: "right" }}>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (runningStatus[item.machine_id].efficiency) : ("0")}%</h6>


                  <div style={{ display: 'flex', width: '100%', height: '10px', borderRadius: '10px', marginBottom: "3px" }}>
                    {(Object.keys(colorWidthData).length && (colorWidthData[item.machine_id])) ?
                      colorWidthData[item.machine_id].map(({ color, width }, index) => (
                        <div key={index} style={{ width, backgroundColor: color, borderRadius: (borderRadius(index, item.machine_id)) }} ></div> //(index == 0 ? '10px 0 0 10px' : (index == colorWidthData[item.sensor_data._id].length - 1 ? '0px 10px 10px 0px' : '0px')) 

                      )) :
                      <div key={index} style={{ width: "100%", backgroundColor: "gray", borderRadius: "10px", height: "10px" }} ></div>}
                  </div>

                  <hr className='line'></hr>

                  <div className='row3' style={{ alignItems: "center" }}>
                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                      <h6 style={{ color: "#5b5656" }}>Best</h6>
                      <h6>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item.machine_id])) ? (runningStatus[item.machine_id].best_shift_efficency ?? "0") : ("0")}%</h6>
                    </div>

                  </div>
                </div>
              ))) : (<></>)

            }

          </div>


        </div>


        <div>
          <p className='error'>{error}</p>
        </div>
      </div>
    </div>
  )
}

export default WebAdmin