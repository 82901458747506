import React from 'react'
import ControllerNavbar from '../../WebAppComponents/ControllerNavbar'
import "../../Styles/DeviceTable.css"
import "../../Styles/UserCard.css"
import "../../Styles/ControllerDashboard.css"
import WebMap from '../WebMap'
import axios from "axios";
import Header from "../../WebAppComponents/Header";
import { getUserDetails } from "../../Utility/AsyncStorageData";
import { useNavigate } from 'react-router-dom';



function ControllerUser() {

    let navigate = useNavigate();
    var userDetails = getUserDetails();

    const [test, setTest] = React.useState(1);
    const [userData, setUserData] = React.useState({});
    const [error, setError] = React.useState('');

    const navbarRender = (data) => {
        // console.log('Data received from child:', data);
        setTest(!test)
    };

    const errortimer = () => {
        setError(undefined)
    }

    const handleUserData = async () => {
        try {

            let url = process.env.REACT_APP_BASE_URL + "/api/listOfUser";
            let body = {
            }
            console.log("************handleUserData************ ")
            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        setUserData(response.data.list)
                        // console.log("************handle-UserData************ ", response.data.list)

                        setError(undefined)

                    }
                    else if (response.data.statusCode === 1) {
                        setError(undefined);
                    }


                    else {

                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                        console.log(" companyWiseDeviceList error: ", response.data.msg);
                    }

                    // setLoading (false);
                }

                else if (response.status == 403) {
                    navigate("/");
                }
            }).catch(error => {
                console.log("error: ", error);
                if (error.response.status == 403) {
                    navigate("/");
                  }

                setError("Slow or no internet connected. Please check your internet connection.")
                setTimeout(() => handleUserData(), 500)
                return
            })
        } catch (error) {
            console.log("error1 ", error)
            setError("Slow or no internet connected. Please check your internet")
            setTimeout(() => handleUserData(), 500)
            return
            // throw error;
        }

        // setLoading(false);
    }

    const ValidateRole = (role) => {
        if (role == "A")
            return "Admin"

        else if (role == "M")
            return "Machine Incharge"

        else if (role == "F")
            return "Floor Manager"


        else if (role == "O")
            return "Owner"

        else
            return "Controller"



    }

    React.useEffect(() => {
        handleUserData();
    }, []);

    return (
        <div className='main'>

            <div>
                <ControllerNavbar />
            </div>

            <div className='Admin_menu' style={{ marginTop: "20px" }}>
                <Header show={3} sendNavbarToParent={navbarRender} />


                <div className='device_table' >
                    <table>
                        <thead>
                            <tr>
                                <th style={{ borderRadius: "10px 0 0 10px" }}>ID</th>
                                <th>User</th>
                                <th>Email or Contact</th>
                                <th>Company Name</th>
                                <th>Role</th>
                                <th style={{ borderRadius: " 0 10px 10px 0" }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userData.length ?
                                (userData.map((item, index) => (
                                    <tr className="device_tr" key={index}>
                                        <td style={{ borderRadius: "10px 0 0 10px" }}>{item.user_id}</td>
                                        <td>{item.name}</td>
                                        <td>{(item.contact != "NA" ? item.contact : item.email)}</td>
                                        <td >{item.company_name}</td>
                                        <td >{ValidateRole(item.role_id)}</td>
                                        <td style={{ borderRadius: " 0 10px 10px 0" }}> <h5 style={{
                                            width: "12px",
                                            height: "12px",
                                            borderRadius: "50%",
                                            margin: "0px",
                                            backgroundColor: (item.is_active == "Y" ? "green" : "red")
                                        }}></h5>
                                        </td>
                                    </tr>
                                )))
                                : <></>
                            }

                        </tbody>
                    </table>


                </div>
            </div>
        </div >

    )
}

export default ControllerUser
