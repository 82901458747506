import React, { useState, useEffect, useRef } from 'react';
import '../Styles/TempratureUser.css';
import axios from 'axios';
import TempratureNavbar from '../Temperature/TempratureNavbar'

const TempratureUser = () => {
  const [data, setData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    name: '',
    contact: '',
    role: '',
    added_by: 1
  });
  const [cities, setCities] = useState([]);
  const [stores, setStores] = useState([]);
  const city_id = useRef(null);
  const [loader, setLoader] = React.useState(true);

  const fetchUserList = async () => {
    try {
      let url = process.env.REACT_APP_BASE_URL + '/temprature/fetchUserList';
      await axios.post(url).then((response) => {
        if (response.status === 200 && response.data.statusCode === 0) {
          setData(response.data.data);
        } else {
          console.log('Error: ', response.data.msg);
        }
        setLoader(false)
      }).catch((error) => {
        setLoader(false)
        console.log('Error: ', error);
      });
    } catch (error) {
      setLoader(false)
      console.log('Error: ', error);
    }
  };

  const fetchRoleList = async () => {
    try {
      let url = process.env.REACT_APP_BASE_URL + '/temprature/fetchRoleList';
      await axios.post(url).then((response) => {
        if (response.status === 200 && response.data.statusCode === 0) {
          let r = []
          for (let key of response.data.data)
            r.push(key.name)
          setRoles(r);
        } else {
          console.log('Error: ', response.data.msg);
        }
      }).catch((error) => {
        console.log('Error: ', error);
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const fetchStoreList = async () => {
    try {
      let body = { city_id: city_id.current }
      let url = process.env.REACT_APP_BASE_URL + '/temprature/fetchStoreList';
      await axios.post(url, body).then((response) => {
        if (response.status === 200 && response.data.statusCode === 0) {
          let r = []
          for (let key of response.data.data)
            r.push({ id: key.store_id, name: key.store_name })
          setStores(r);
        } else {
          console.log('Error: ', response.data.msg);
          setStores([]);
        }
      }).catch((error) => {
        console.log('Error: ', error);
        setStores([]);
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const fetchCitiesList = async () => {
    try {
      let url = process.env.REACT_APP_BASE_URL + '/temprature/fetchCityList';
      await axios.post(url).then((response) => {
        if (response.status === 200 && response.data.statusCode === 0) {
          let r = []
          for (let key of response.data.data)
            r.push({ id: key.city_id, name: key.city_name })
          setCities(r);
        } else {
          console.log('Error: ', response.data.msg);
        }
      }).catch((error) => {
        console.log('Error: ', error);
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "city_id") {
      city_id.current = value
      fetchStoreList()
    }
    setNewUser({ ...newUser, [name]: value });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let url = process.env.REACT_APP_BASE_URL + '/temprature/addUser';
      await axios.post(url, newUser).then((response) => {

        if (response.status === 200 && response.data.statusCode === 0) {
          fetchUserList();
          setIsFormOpen(false);
          setNewUser({ name: '', contact: '', role: '', added_by: 1 });
        } else {
          console.log('Error: ', response.data.msg);
        }
      }).catch((error) => {
        console.log('Error: ', error);
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  useEffect(() => {
    fetchUserList();
    fetchRoleList();
    fetchCitiesList();
  }, []);

  return (


    <div className='main'>

      <div>
        <TempratureNavbar />
      </div>
      {
        loader ?
          <div className='loadtemp' style={{ alignItems: "center" }}> <img src="https://tmmapi.9930i.com/loader" alt="Computer man" style={{ height: "150px" }} />
          </div>
          :
          <div style={{ width: "100%" }}>
            <div className='tableHeading'>
              <h2>Users</h2>
            </div>

            <div className="temp_table" style={{
              marginTop: "30px",
              alignItems: "flex-start"
            }}>
              <table>
                <thead>
                  <tr>
                    <th style={{ borderRadius: "10px 0 0 10px" }}>S.No.</th>
                    <th>Name</th>
                    <th>Contact</th>
                    <th>City Name</th>
                    <th>Store Name</th>
                    <th style={{ borderRadius: "0 10px 10px 0" }}>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((user, index) => (
                    <tr key={index}>
                      <td style={{ borderRadius: "10px 0 0 10px" }}>{index + 1}</td>
                      <td>{user.name}</td>
                      <td>{user.contact}</td>
                      <td>{user.city_name}</td>
                      <td>{user.store_name}</td>
                      <td style={{ borderRadius: "0 10px 10px 0" }}>{user.role}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <button className="add-button" onClick={() => setIsFormOpen(true)}>+</button>

              {isFormOpen && (
                <div className="form-overlay">
                  <div className="form-container">
                    <button className="close-button" onClick={() => {setIsFormOpen(false); setNewUser({})}}>×</button>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label>Name</label>
                        <input type="text" name="name" value={newUser.name} onChange={handleInputChange} required />
                      </div>
                      <div className="form-group">
                        <label>Contact</label>
                        <input type="text" name="contact" value={newUser.contact} onChange={handleInputChange} required />
                      </div>
                      <div className="form-group">
                        <label>City Name</label>
                        <select name="city_id" value={newUser.city_id} onChange={handleInputChange} required>
                          <option value="" hidden>Select City</option>
                          {cities.map((city) => (
                            <option key={city.id} value={city.id}>{city.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Store Name</label>
                        <select name="store_id" value={newUser.store_id} onClick={handleInputChange} onChange={handleInputChange} onBlur={handleInputChange} required>
                          <option value="" hidden>Select Store</option>
                          {stores.map((store) => (
                            <option key={store.id} value={store.id}>{store.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Role</label>
                        <select name="role" value={newUser.role} onChange={handleInputChange} required>
                          <option value="" disabled>Select Role</option>
                          {roles.map((role, index) => (
                            <option key={index} value={role}>{role}</option>
                          ))}
                        </select>
                      </div>
                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
      }
    </div>
  );
};

export default TempratureUser;
