import React, { useState, useEffect, useRef } from "react";
import flovation from "../Assets/logo1.png"
import "../Styles/Temperature.css"
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import TempratureNavbar from '../Temperature/TempratureNavbar'
import DatePicker from "react-datepicker";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';

var intervalId = 0;

function TempDetailsLog() {

    let navigate = useNavigate();
    const location = useLocation();
    const item = location.state;
    console.log("detail page", item)

    // const [isEditing, setIsEditing] = useState(false);
    // const [currentName, setCurrentName] = useState(name);
    // const [currentLocation, setCurrentLocation] = useState(loc);

    const [currentTemp, setCurrentTemp] = useState(item.current_temp)
    const [currentHumi, setCurrentHumi] = useState(item.current_humidity)
    const currentGate = useRef(item.gate)
    const [data, setData] = useState([])
    const [gateOpenCount, setGateOpenCount] = useState([])
    const [graphData, setGraphData] = useState([])
    const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));

    const [loading, setLoading] = useState(true);
    var start_value = useRef(0);

    var current_date = useRef(moment().format("YYYY-MM-DD"));
    const offset_current = useRef(0);
    const tableRef = useRef(null);
    const limit = 20;

    const NotificationtableRef = useRef(null);
    const Notification_offset = useRef(0);
    const [triggerData, setTriggerData] = useState([])
    const [loadingNotification, setLoadingNotification] = useState(true);



    // const handleSave = (newName, newLocation) => {
    //     console.log("name , location :- ", newName, newLocation)
    //     // setIsEditing(false);
    // };


    const handleDateFrom = (changedDate) => {
        // setFromDate(moment(changedDate).format('YYYY-MM-DD'))
        setFromDate((prevItems) => (moment(changedDate).format('YYYY-MM-DD')));
        current_date.current = moment(changedDate).format('YYYY-MM-DD');
        offset_current.current = 0
        Notification_offset.current = 0
        setData([])
        setGraphData([])
        setTriggerData([])
        DetailData()
        NotificationTrigger()
        console.log("Date From:", moment(changedDate).format('YYYY-MM-DD'));
    }

    const DetailData = async (offset) => {

        try {
            setLoading(true)
            console.log("starting api")

            let url = process.env.REACT_APP_BASE_URL + "/temprature/fetchTempratureLog";
            let body = {
                device_id: item.device_id,
                from: current_date.current,
                to: current_date.current,
                limit,
                offset: offset ?? 0,
                start_value : start_value.current

            }
            await axios.post(url, body, {
                headers: {
                    'authorization': ``
                },
            }).then(response => {

                if (response.status === 200) {

                    setLoading(false)
                    console.log(" api response")
                    if (response.data.statusCode === 0) {

                        // console.log(" getTemperature : ", response.data);
                        setCurrentTemp(response.data.data[0].temprature);
                        setCurrentHumi(response.data.data[0].humidity);
                        setData((prevItems) => [...prevItems, ...response.data.data]);
                        if(start_value.current == 0)
                            start_value.current = response.data.data[0].id
                        const transformedData = response.data.data.map(entry => {
                            return {
                                time: moment(entry.doa).format('HH:mm:ss'),
                                temp: entry.temprature,
                                humidity: entry.humidity
                            };
                        }).reverse();
                        // console.log("transformedData", transformedData)
                        setGraphData(transformedData)


                        const gateOpen = response.data.data.filter(entry => entry.gate == 1).length;
                        setGateOpenCount(gateOpen)
                        // console.log(`Gate opened ${gateOpenCount} times.`);
                        // currentGate.current = 1
                    }
                }

                else if (response.status == 403) {
                    navigate("/");
                }
            }).catch(error => {
                console.log("error: ", error);
                setLoading(false)
                console.log("error")
                if (error.response.status == 403) {
                    navigate("/");
                }
                return
            })
        } catch (error) {
            console.log("error1 ", error)
            setLoading(false)
            console.log("error1")
            return
            // throw error;
        }
    }

    const NotificationTrigger = async (noti_offset) => {
        try {
            setLoadingNotification(true)

            let url = process.env.REACT_APP_BASE_URL + "/temprature/fetchTempWtsAppTrigger";
            let body = {
                device_id: item.device_id,
                from: current_date.current,
                to: current_date.current,
                limit,
                offset: noti_offset ?? 0
            }
            await axios.post(url, body, {
                headers: {
                    'authorization': ``
                },
            }).then(response => {

                if (response.status === 200) {

                    setLoadingNotification(false)
                    if (response.data.statusCode === 0) {
                        console.log("fetchTempWtsAppTrigger", response.data)
                        setTriggerData((prevItems) => [...prevItems, ...response.data.data]);


                    }
                }

                else if (response.status == 403) {
                    navigate("/");
                }
            }).catch(error => {
                console.log("error: ", error);
                setLoadingNotification(false)
                console.log("error")
                if (error.response.status == 403) {
                    navigate("/");
                }
                return
            })
        } catch (error) {
            console.log("error1 ", error)
            setLoadingNotification(false)
            console.log("error1")
            return
            // throw error;
        }
    }



    // clearInterval(intervalId);
    // intervalId = setInterval(() => {
    //     DetailData();
    // }, 30000);

    useEffect(() => {
        const tableElement = tableRef.current;

        const handleScroll = () => {
            // console.log( Math.round(tableElement.scrollTop),  tableElement.clientHeight, tableElement.scrollHeight)
            // console.log(Math.round(tableElement.scrollTop) + tableElement.clientHeight >= tableElement.scrollHeight)
            setLoading(true)
            if (Math.round(tableElement.scrollTop) + tableElement.clientHeight >= tableElement.scrollHeight) {
                setLoading(true)
                // console.log("load more loaging true")
                offset_current.current = offset_current.current + limit
                DetailData(offset_current.current)
                // console.log("load more", offset_current.current)
            }
        };

        tableElement.addEventListener('scroll', handleScroll);

        return () => {
            tableElement.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const tableElement = NotificationtableRef.current;

        const handleScroll = () => {
            setLoadingNotification(true)
            if (Math.round(tableElement.scrollTop) + tableElement.clientHeight >= tableElement.scrollHeight) {
                setLoadingNotification(true)
                Notification_offset.current = Notification_offset.current + limit
                NotificationTrigger(Notification_offset.current)
            }
        };

        tableElement.addEventListener('scroll', handleScroll);

        return () => {
            tableElement.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        DetailData();
        NotificationTrigger();
    }, []);

    return (

        <div className='main'>

            <div>
                <TempratureNavbar />
            </div>
            <div style={{ width: "100%" }}>
                <div className="Header_temp">
                    <div className="logo">
                        <img src={flovation} alt="flovation_Logo" />
                    </div>
                    <div style={{ width: "60%" }} className='com_name'>
                        <h3>SuperManager</h3>
                    </div>
                    <div className="dateFilter">
                        <div className='FromDate'>
                            <label>Date</label>
                            <DatePicker
                                selected={fromDate}
                                placeholderText='Select From Data'
                                onChange={handleDateFrom}
                                className="custom-datepicker-report"
                                dateFormat="YYYY/MM/dd"
                                maxDate={new Date()}
                            />
                        </div>
                    </div>
                </div>
                <div className="bodyTemp">
                    <div className="tempDeciveDetail">
                        {/* <h3 style={{ marginBottom: "10px" }}>Device Details</h3> */}
                        <h3 style={{ marginBottom: "10px", textAlign: "center" }}>{item.device_name}</h3>
                        {
                            currentGate.current == null ?
                                <div className="currentTempHumi">
                                    <h4>Min Temp: {item.min_temp} °C</h4>
                                    <h4>Cur Temp: {currentTemp} °C</h4>
                                    <h4>Max Temp: {item.max_temp} °C</h4>
                                    <h4>Cur Humidity: {currentHumi} %</h4>
                                </div>
                                :
                                <div className="currentTempHumi">
                                    <h4>Currently Gate is {currentGate.current == 0 ? "Close" : "Open"}</h4>
                                    {/* <h4>Open Count : {gateOpenCount} Times</h4> */}
                                </div>
                        }
                        {/* <i class="fa-regular fa-pen-to-square" ></i> */}
                    </div>


                    <div>
                        {currentGate.current == null ?
                            <div className="tempChart">
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart
                                        width={500}
                                        height={300}
                                        data={graphData}
                                        margin={{
                                            top: 15, right: 0, left: 10, bottom: 0,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="time" />
                                        <YAxis />
                                        <Tooltip />
                                        <Line type="monotone" dataKey="temp" stroke="#8884d8" activeDot={{ r: 8 }} />
                                        {/* <Line type="monotone" dataKey="humidity" stroke="#82ca9d" /> */}
                                    </LineChart>
                                </ResponsiveContainer>

                            </div>
                            : <></>
                        }


                        <div className="tempTable" style={{ padding: 0 }}>
                            <div ref={tableRef}
                                style={{
                                    height: '400px',
                                    overflowY: 'scroll',
                                }}>
                                {
                                    currentGate.current == null ?
                                        <table className="table_block">
                                            <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }} >
                                                <tr>
                                                    <th style={{ borderRadius: "10px 0 0 10px" }}>Sno</th>
                                                    <th>Date</th>
                                                    <th>Temperature °C</th>
                                                    <th style={{ borderRadius: " 0 10px 10px 0" }}>Humidity %</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {data.length ?
                                                    (data.map((item, index) => (
                                                        <tr className="device_tr" key={index} >
                                                            <td style={{ borderRadius: "10px 0 0 10px" }}>{index + 1}</td>
                                                            <td >{moment(item.doa).format('DD/MM/YYYY, HH:mm:ss')}</td>
                                                            <td className='action'>{item.temprature}</td>
                                                            <td className='action' style={{ borderRadius: " 0 10px 10px 0" }}>{item.humidity}</td>
                                                        </tr>
                                                    )))
                                                    : <></>
                                                }

                                            </tbody>
                                        </table>
                                        :
                                        <table className="table_block">
                                            <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }} >
                                                <tr>
                                                    <th style={{ borderRadius: "10px 0 0 10px" }}>Sno</th>
                                                    <th>Date</th>
                                                    <th style={{ borderRadius: " 0 10px 10px 0" }}>Gate Status</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {data.length ?
                                                    (data.map((item, index) => (
                                                        <tr className="device_tr" key={index} >
                                                            <td style={{ borderRadius: "10px 0 0 10px" }}>{index + 1}</td>
                                                            <td >{moment(item.doa).format('DD/MM/YYYY, HH:mm:ss')}</td>
                                                            <td className='action' style={{ borderRadius: " 0 10px 10px 0" }}>{item.gate == 0 ? "Close" : "Open"}</td>
                                                        </tr>
                                                    )))
                                                    : <></>
                                                }

                                            </tbody>
                                        </table>
                                }

                                {loading && (
                                    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                                        <p>loading....</p>
                                    </div>
                                )}
                            </div>
                        </div>


                        <h3 style={{ margin: "2% 5%", color: " rgb(34, 51, 84)" }}>Notification Trigger</h3>

                        <div className="tempTable" style={{ padding: 0 }}>
                            <div ref={NotificationtableRef}
                                style={{
                                    maxHeight: '400px',
                                    overflowY: 'scroll',
                                    height: "fit-content"
                                }}>
                                <table className="table_block">
                                    <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                                        <tr>
                                            <th style={{ borderRadius: "10px 0 0 10px" }}>Sno</th>
                                            <th>Date & Time</th>
                                            <th style={{ borderRadius: " 0 10px 10px 0" }}> Notification at Temperature in °C</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {triggerData.length ?
                                            (triggerData.map((item, index) => (
                                                <tr className="device_tr" key={index} >
                                                    <td style={{ borderRadius: "10px 0 0 10px" }}>{index + 1}</td>
                                                    <td >{moment(item.doa).format('DD/MM/YYYY, HH:mm:ss')}</td>
                                                    <td className='action' style={{ borderRadius: " 0 10px 10px 0" }}>{item.temp_value}</td>
                                                </tr>
                                            )))
                                            : <p style={{ textAlign: "center", width: "90%", textTransform: "capitalize" }}> No data found</p>
                                        }

                                    </tbody>
                                </table>
                                {loadingNotification && (
                                    <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                                        <p>loading....</p>
                                    </div>
                                )}
                            </div>
                        </div>



                    </div>

                </div>
            </div>


            {/* {isEditing && (
                <div className="popup">
                    <div className="popup-content">
                        <h3>Edit Details</h3>
                        <label>
                            Name
                            <input type="text" value={currentName} onChange={(e) => setCurrentName(e.target.value)} />
                        </label>
                        <label>
                            Location
                            <input type="text" value={currentLocation} onChange={(e) => setCurrentLocation(e.target.value)} />
                        </label>
                        <button onClick={() => handleSave(currentName, currentLocation)}>Save</button>
                        <button onClick={() => setIsEditing(false)}>Cancel</button>
                    </div>
                </div>
            )} */}

        </div>
    )
}

export default TempDetailsLog

