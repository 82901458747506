import React from 'react';
import '../Styles/SessionLogout.css';
import { useNavigate } from 'react-router-dom';

const SessionLogout = () => {
    let navigate = useNavigate();

    setTimeout(() => navigate("/"), 4000)

    return (
        <div className="logout-container">
            <div className="chat-box">
                <div className="chat-header">
                    <div className='header_icon'>
                        <i class="fa-solid fa-triangle-exclamation"></i>
                    </div>
                    <div className='header_name'>
                        <h3 style={{fontWeight: "900"}}>Your Session has expired</h3>
                        <h4 style={{fontWeight: "400"}}>Please Login again to continue</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SessionLogout;