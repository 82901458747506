import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import TempratureNavbar from '../Temperature/TempratureNavbar'

const TempratureDevice = () => {
  const [data, setData] = useState([]);
  const [devices, setDevices] = useState([]);
  const [cities, setCities] = useState([]);
  const [stores, setStores] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [newDevice, setNewDevice] = useState({});
  const city_id = useRef(null);
  const [loader, setLoader] = React.useState(true);

  const fetchDeviceList = async () => {
    try {
      let url = process.env.REACT_APP_BASE_URL + '/temprature/fetchDeviceList';
      await axios.post(url).then((response) => {
        if (response.status === 200 && response.data.statusCode === 0) {
          setData(response.data.data);
        } else {
          console.log('Error: ', response.data.msg);
        }
        setLoader(false)
      }).catch((error) => {
        setLoader(false)
        console.log('Error: ', error);
      });
    } catch (error) {
      setLoader(false)
      console.log('Error: ', error);
    }
  };

  const fetchCitiesList = async () => {
    try {
      let url = process.env.REACT_APP_BASE_URL + '/temprature/fetchCityList';
      await axios.post(url).then((response) => {
        if (response.status === 200 && response.data.statusCode === 0) {
          let r = []
          for (let key of response.data.data)
            r.push({ id: key.city_id, name: key.city_name })
          setCities(r);
        } else {
          console.log('Error: ', response.data.msg);
        }
      }).catch((error) => {
        console.log('Error: ', error);
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const fetchStoreList = async () => {
    try {
      let body = { city_id: city_id.current }
      let url = process.env.REACT_APP_BASE_URL + '/temprature/fetchStoreList';
      await axios.post(url, body).then((response) => {
        if (response.status === 200 && response.data.statusCode === 0) {
          let r = []
          for (let key of response.data.data)
            r.push({ id: key.store_id, name: key.store_name })
          setStores(r);
        } else {
          console.log('Error: ', response.data.msg);
          setStores([]);
        }
      }).catch((error) => {
        console.log('Error: ', error);
        setStores([]);
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name == "city_id") {
      city_id.current = value
      fetchStoreList()
    }
    console.log(name, value)
    setNewDevice({ ...newDevice, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(newDevice);

      let url = process.env.REACT_APP_BASE_URL + '/temprature/pairNewDevice';
      await axios.post(url, newDevice).then((response) => {
        if (response.status === 200 && response.data.statusCode === 0) {
          fetchDeviceList();
          setIsFormOpen(false);
          setNewDevice({});
        } else {
          console.log('Error: ', response.data.msg);
        }
      }).catch((error) => {
        console.log('Error: ', error);
      });
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  useEffect(() => {
    fetchDeviceList();
    fetchCitiesList();
  }, []);

  return (
    <div className='main'>

      <div>
        <TempratureNavbar />
      </div>

      {
        loader ?
          <div className='loadtemp' style={{ alignItems: "center" }}> <img src="https://tmmapi.9930i.com/loader" alt="Computer man" style={{ height: "150px" }} />
          </div>
          :
          <div style={{ width: "100%" }}>
            <div className='tableHeading'>
              <h2>Devices</h2>
            </div>
            <div className="temp_table" style={{
              marginTop: "30px",
              alignItems: "flex-start"
            }}>
              <table>
                <thead>
                  <tr>
                    <th style={{ borderRadius: "10px 0 0 10px" }}>S.No.</th>
                    <th>Device ID</th>
                    <th>Device Name</th>
                    <th>Store Name</th>
                    <th>City Name</th>
                    <th>Min Temp</th>
                    <th>Max Temp</th>
                    <th style={{ borderRadius: "0 10px 10px 0" }}>WS Number</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((device, index) => (
                    <tr key={index}>
                      <td style={{ borderRadius: "10px 0 0 10px" }}>{index + 1}</td>
                      <td>{device.device_id}</td>
                      <td>{device.device_name}</td>
                      <td>{device.store_name}</td>
                      <td>{device.city_name}</td>
                      <td>{device.min_thershold}</td>
                      <td>{device.max_thershold}</td>
                      <td style={{ borderRadius: "0 10px 10px 0" }}>{device.ws_number}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <button className="add-button" onClick={() => setIsFormOpen(true)}>+</button>

              {isFormOpen && (
                <div className="form-overlay">
                  <div className="form-container">
                    <button className="close-button" onClick={() => {setIsFormOpen(false); setNewDevice({})}}>×</button>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label>City</label>
                        <select name="city_id" value={newDevice.city_id} onChange={handleInputChange} required>
                          <option value="" hidden>Select City</option>
                          {cities.map((city) => (
                            <option key={city.id} value={city.id}>{city.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Store</label>
                        <select name="store_id" value={newDevice.store_id} onClick={handleInputChange} onChange={handleInputChange} required>
                          <option value="" hidden>Select Store</option>
                          {stores.map((store) => (
                            <option key={store.id} value={store.id}>{store.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Device Key</label>
                        <input type="text" name="device_key" value={newDevice.device_key} onChange={handleInputChange} required />
                      </div>
                      <div className="form-group">
                        <label>Device Pass</label>
                        <input type="text" name="device_pass" value={newDevice.device_pass} onChange={handleInputChange} required />
                      </div>
                      <button type="submit">Submit</button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
      }

    </div>
  );
};

export default TempratureDevice;
