import React from 'react'
import Navbar from './Navbar'
import "../Styles/DeviceTable.css"
import { getUserDetails, setDeviceDetails, SetDeviceMachines } from "../Utility/AsyncStorageData";
import Modal from "./Modal";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Report from '../Utility/Report'
import Header from "./Header";
import { Scanner } from '@yudiel/react-qr-scanner';



function WebDevice() {
  // console.log("************WebDevice************ ")

  let navigate = useNavigate();
  var userDetails = getUserDetails();

  const [deviceList, setDeviceList] = React.useState([])
  const [error, setError] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [deviceData, setDeviceData] = React.useState({})
  const [device_Id, setDevice_Id] = React.useState("");
  const [reportDeviceId, setReportDeviceId] = React.useState("");
  const [openExcel, setOpenExcel] = React.useState(false);
  const [test, setTest] = React.useState(1);

  const handleClose = () => {
    setDeviceData([]);
    setOpen(false);
  };

  const handleOpen = (item) => {
    // console.log(item)
    setDevice_Id(item.device_id)
    setDeviceData(item)
    setOpen(true);
  };


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setDeviceData(values => ({ ...values, [name]: value }))
  }


  const handleFetchDevice = async () => {
    try {

      let url = process.env.REACT_APP_BASE_URL + "/device/companyWiseDeviceList";
      let body = {
        comp_num: userDetails.comp_num,
      }
      // console.log("************companyWiseDeviceList************ ", body)
      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            setDeviceList(response.data.data[0].deviceDetails)
            // console.log("************companyWiseDeviceList************ ", response.data.data[0].deviceDetails)

            setError(undefined)

          }
          else if (response.data.statusCode === 1) {
            setError(undefined);
          }


          else {

            setError(response.data.msg)
            setTimeout(() => errortimer(), 2000)
            console.log(" companyWiseDeviceList error: ", response.data.msg);
          }

          // setLoading (false);
        }


        else if (response.status == 403) {
          navigate("/");
        }

      }).catch(error => {
        console.log("error: ", error);
        if (error.response.status == 403) {
          navigate("/");
        }
        // Alert.alert("ERROR")
        setError("ERROR")
        setTimeout(() => errortimer(), 2000)

        setError("Slow or no internet connected. Please check your internet connection.")
        setTimeout(() => handleFetchDevice(), 500)
        return
      })
    } catch (error) {
      console.log("error1 ", error)
      setError("Slow or no internet connected. Please check your internet")
      setTimeout(() => handleFetchDevice(), 500)
      return
      // throw error;
    }

    // setLoading(false);
  }

  const submitForm = async () => {
    // console.log("deviceData", deviceData)
    try {

      // if (!device_name || !refresh_rate || !location) {
      //   setError("Enter all fields")
      //   setTimeout(() => errortimer(), 2000)
      //   return
      // }


      let url = process.env.REACT_APP_BASE_URL + "/device/configure_device";
      let body = {
        comp_num: userDetails.comp_num,
        device_id: device_Id,
        device_name: deviceData.device_name,
        baudrate: deviceData.baudrate,
        parity: deviceData.parity,
        refresh_rate: deviceData.refresh_rate,
        location: deviceData.location,
      }
      // console.log("****************configure_device******************* ", body)
      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {
        // console.log("response: ", response.data);

        if (response.status === 200) {

          if (response.data.statusCode === 0) {

            // console.log("response Data: ", response.data);
            setDevice_Id(response.data.device_id)
            // setDeviceSetting(false)
            setOpen(false);
            handleFetchDevice();

            setError(response.data.msg)
            setTimeout(() => errortimer(), 2000)
            return
          }
          else {
            console.log("configure_device msg ", response.data.msg)
            setError(response.data.msg)
            setTimeout(() => errortimer(), 2000)
            return
          }
        }
      }).catch(error => {
        console.log("error: ", error);
        if (error.response.status == 403) {
            navigate("/");
          }
        setError("ERROR")
        setTimeout(() => errortimer(), 2000)
        return
      })
    } catch (error) {
      console.log("error ", error)
      throw error;
    }
  };

  const navigateSensor = (item) => {
    // console.log("navigateSensor", item)
    setDeviceDetails(JSON.stringify(item));
    navigate("/webSensor");
  }
  const deviceMachines = (item) => {
    // console.log("deviceMachines", item)
    SetDeviceMachines(JSON.stringify(item));
    navigate("/deviceMachines");
  }

  const receiveDataFromChild = (data) => {
    // console.log('Data received from child:', data);
    setOpenExcel(false);
  };

  const openExcelFlie = (item) => {
    setOpenExcel(true)
    setReportDeviceId(item.device_id)
  }


  const errortimer = () => {
    setError(undefined)
  }

  React.useEffect(() => {
    handleFetchDevice();
  }, []);

  const navbarRender = (data) => {
    // console.log('Data received from child:', data);
    setTest(!test)
  };

  const customScannerStyles = {
    container: {
      height: '30%',
      // paddingTop: '0',
      width: '30%',
      // margin: '10% 15%',
      position: 'absolute !important',
    },
    video: {
      width: '100%',
      height: '100%',
    },
    finderBorder: "0"
  };


  return (
    <div className='main'>

      <div>
        <Navbar />
      </div>

      <div className='Admin_menu'>

        <Header show={1} sendNavbarToParent={navbarRender} />

        <h3 style={{ width: "100%", textAlign: "center", color: "rgb(5, 19, 120)" }}>Iot Gateway</h3>
        <div className='device_table'>
          <table>
            <thead>
              <tr>
                <th style={{ borderRadius: "10px 0 0 10px" }}>Device Name</th>
                <th>Machine Connected</th>
                <th style={{ borderRadius: " 0 10px 10px 0" }}  >Action</th>
              </tr>
            </thead>
            <tbody>
              {deviceList.map((item, index) => (
                <tr className="device_tr" key={index}>
                  <td style={{ borderRadius: "10px 0 0 10px" }} onClick={() => navigateSensor(item)}>{item.device_name}</td>
                  <td onClick={() => navigateSensor(item)}>{item.sensor_count}</td>
                  <td className='action' style={{ borderRadius: " 0 10px 10px 0" }}>
                    <i className="fa-solid fa-pen" onClick={() => handleOpen(item)}></i>
                    <i className="fa fa-sitemap" onClick={() => deviceMachines(item)}></i>
                    <i className="fa fa-file-excel-o" onClick={() => openExcelFlie(item)} ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>


        </div>

        <Modal isOpen={open}>

          <div style={{ display: "inline-flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            <h2 style={{ margin: "0", padding: "0", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>Edit Device</h2>
            <i className="fa fa-close" onClick={handleClose}></i>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "2px 40px" }} className='fields'>
            <div>
              <label>Enter Device Name</label>
              <input className='inputField'
                type="text"
                name="device_name"
                value={deviceData.device_name || ""}
                onChange={handleChange}
              />
            </div>

            <div>
              <label>Select Baurd Rate</label>
              <select
                className="custom-dropdown"
                name="baudrate"
                value={deviceData.baudrate}
                onChange={handleChange}
              >
                <option value="" hidden>Select...</option> {/* Placeholder option */}
                <option value="4800">4800</option>
                <option value="9600">9600</option>
                <option value="14400">14400</option>
                <option value="19200">19200</option>
                <option value="115200">115200</option>
                <option value="128000">128000</option>
              </select>
            </div>


            <div>
              <label >Select Parity</label>
              <select
                name="parity"
                className="custom-dropdown"
                value={deviceData.parity}
                onChange={handleChange}
              >
                <option value="" hidden>Select...</option> {/* Placeholder option */}
                <option value="Even">Even</option>
                <option value="Odd">Odd</option>
                <option value="None">None</option>
              </select>
            </div>

            <div>
              <label >Enter Refresh Rate</label>
              <input className='inputField'
                type="text"
                name="refresh_rate"
                value={deviceData.refresh_rate || ""}
                onChange={handleChange}
              />
            </div>

            <div>
              <label >Enter Location</label>
              <input
                type="text"
                name="location"
                value={deviceData.location || ""}
                onChange={handleChange}
                className='inputField'
              />
            </div>

            <button onClick={submitForm} style={{
              backgroundColor: "#252B42 ", height: "30px", borderRadius: "10px", textDecoration: "none",
              border: "0", marginTop: "30px", color: "white", fontSize: "15px", fontWeight: "500"
            }}>Submit</button>

          </div>
        </Modal>

        <Modal isOpen={openExcel}>
          <div>
            <div className='report_download'>
              <h3>Report Download</h3>
              <i className="fa-solid fa-xmark" onClick={() => setOpenExcel(false)}></i>
            </div>
            <Report show={1} deviceData={reportDeviceId} sendDataToParent={receiveDataFromChild} />
          </div>
        </Modal>

        <Modal isOpen={openAdd}>
          <div>
            <i className="fa fa-close" onClick={() => { setOpenAdd(false) }}></i>

            <Scanner
              onResult={(text, result) => console.log(text, result)}
              onError={(error) => console.log(error?.message)}
              scanning={true}
              styles={customScannerStyles}
            />

          </div>

        </Modal>

        <p className='error1'>{error}</p>
        <div className='addBlock1'>
          <i class="fa fa-plus-square" ></i>
        </div>

      </div>
    </div>
  )
}

export default WebDevice