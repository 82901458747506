import React, { useRef, useEffect } from 'react';
import axios from "axios";
import "../Styles/TvDashboard.css"
import flovation from "../Assets/logo1.png"
import { setTvDetails, getTvDetails, removeTvDetails, removeUserDetails  } from "../Utility/AsyncStorageData";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
var runtimeid = 0;
var intervalId = 0;
var statueBarInterval = 9;

function TvDashboard() {
  console.log("**************TvDashboard*****************")
  let navigate = useNavigate();

  var TvDetails = getTvDetails();
  const [machineData, setMachineData] = React.useState([]);
  const [machineStates, setMachineStates] = React.useState([]);
  const [error, setError] = React.useState(null);
  const gb_machine_id = useRef("");
  const [graph, setGraph] = React.useState([]);
  const [colorWidthData, setColorWidthData] = React.useState([]);


  const refreshAccessToken = () => {

    // console.log("refreshAccessToken");
    try {
      let url = process.env.REACT_APP_BASE_URL + "/tv/verifyTvCode";
      let body = {
        tv_code: TvDetails.tv_code,
      }

      // console.log("response: ", body, url);
      axios.post(url, body).then(response => {

        if (response.status === 200) {
          if (response.data.statusCode === 0) {
            // console.log("response: ", response.data.data);
            setTvDetails(JSON.stringify(response.data.data));
            TvDetails = getTvDetails();
          }
        }
        else {
          setError(response.data.msg);
          setTimeout(() => clearErrorMsg(), 3000)
        }
      })

    } catch (error) {
      console.log("ERROR");
      if (error.response.status === 403) {
        navigate("/");
      }
      setError("Network Error");
      setTimeout(() => clearErrorMsg(), 3000)

    }
  }

  const dashProgressBar = async () => {
    try {
      // console.log('*********** dashProgressBar api ***********');

      if (gb_machine_id.current.length !== 0) {

        let url = process.env.REACT_APP_BASE_URL + '/shift/fetchLogWiseData';
        let a = ""
        for (let key of gb_machine_id.current) {
          a = a + key.replace("'", "")
        }

        let body = {
          machine_ids: a
        };

        // console.log(" dashProgressBar body", body)
        await axios
          .post(url, body, {
            headers: {
              authorization: `${TvDetails.access_token}`,
            },
          })
          .then(response => {
            if (response.status === 200) {
              if (response.data.statusCode === 0) {
                // setMachineData(response.data.list)
                // setMachineStates(response.data)
                setColorWidthData(response.data.result)
                // console.log(response.data.result)
              } else {

                console.log("dashProgressBar api error", response.data.msg)
                setError("ERROR")
                // setTimeout(() => errortimer(), 2000);
              }
            }
            else if (response.status === 403) {
              // console.log("refreshAccessToken: ");
              refreshAccessToken();
              dashProgressBar();
            }
          })
          .catch(error => {
            // Alert.alert("ERROR")
            refreshAccessToken();
            if (error.response.status === 403) {
              navigate("/");
            }
            console.log("ERROR", error)
            setError('ERROR');
            setTimeout(() => clearErrorMsg(), 2000);
          });
        // setLoading(false);
      }

    } catch (error) {
      console.log('error ', error);
      setError('ERROR');
      setTimeout(() => clearErrorMsg(), 2000);
      // statueBarInterval = setTimeout(() => dashProgressBar(), 30000)
      throw error;
    }
  }

  const TvMachineAssignList = () => {

    console.log("TvMachineAssignList");
    try {
      let url = process.env.REACT_APP_BASE_URL + "/dashboard/tvDashMachineAssignList";
      let body = {
        dash_id: TvDetails.dash_id,
        comp_num: TvDetails.comp_num
      }

      // console.log("TvMachineAssignList body", body);

      axios.post(url, body, {
        headers: {
          'authorization': `${TvDetails.access_token}`
        },
      }).then(response => {

        if (response.status === 200) {
          if (response.data.statusCode === 0) {

            const machineIds = response.data.data.map(item => item.sensor_data._id);
            const machineIdsString = machineIds.map(item => `${item}`).join(', ');

            // console.log("machineIds===================", machineIdsString);

            const elements = machineIdsString.split(', ');
            gb_machine_id.current = elements.map(element => `'${element}'`).join(', ');

            // console.log(gb_machine_id.current)
            setGraph(response.data.data)
            Runtime()

          }

          else {
            console.log("response: ", response.data.msg);
            setError(response.data.msg);
            setTimeout(() => clearErrorMsg(), 3000)
          }
        }
        else if (response.status === 403) {
          // console.log("refreshAccessToken: ");
          refreshAccessToken();
          TvMachineAssignList();
        }

      }).catch(error => {
        if (error.response.status === 403) {
          navigate("/");
        }
        refreshAccessToken();
        Runtime();
      });

    } catch (error) {
      console.log("ERROR");

    }
  }

  const Runtime = async () => {
    try {
      console.log('*********** getMachineStatus api ***********');

      if (gb_machine_id.current.length !== 0) {


        let url = process.env.REACT_APP_BASE_URL + '/sensor/getMachineStatus';
        let body = {
          // machineState: machineState,
          machine_id: gb_machine_id.current
        };

        // body.machine_id = elements.map(element => `'${element}'`).join(', ');
        // console.log(" getMachineStatus body", body)
        await axios
          .post(url, body, {
            headers: {
              authorization: `${TvDetails.access_token}`,
            },
          })
          .then(response => {
            if (response.status === 200) {
              if (response.data.statusCode === 0) {

                setMachineData(response.data.list)
                setMachineStates(response.data)

                statueBarInterval = statueBarInterval + 1;
                if (statueBarInterval >= 10) {

                  // console.log("statueBarInterval", statueBarInterval)
                  statueBarInterval = 0;
                  dashProgressBar()
                }

              }

              else {

                console.log("getMachineStatus api error", response.data.msg)
                setError("ERROR")
                // setTimeout(() => errortimer(), 2000);
              }
            }
            else if (response.status === 403) {
              console.log("refreshAccessToken: ");
              if (error.response.status === 403) {
                navigate("/");
              }
              refreshAccessToken();
              Runtime();
            }
          })
          .catch(error => {
            // Alert.alert("ERROR")
            refreshAccessToken();
            Runtime();
            console.log("ERROR", error)
            setError('ERROR');
            setTimeout(() => clearErrorMsg(), 2000);
          });
        // setLoading(false);
      }
      runtimeid = setTimeout(() => { Runtime(); }, 30000)

    } catch (error) {
      console.log('error ', error);
      setError('ERROR');
      setTimeout(() => clearErrorMsg(), 2000);
      runtimeid = setTimeout(() => { Runtime() }, 30000)
      throw error;
    }
  }

  const machineTime = (mech_id) => {
    // console.log(mech_id, machineData[mech_id])
    if (machineData[mech_id].machine_current_status === "D") {
      return (
        <h1 style={{ color: "blue", fontSize: "45px" }} className='clock'>{(machineData[mech_id].on_time)}</h1>
      )
    }
    else {
      if (machineData[mech_id].efficiency >= 60) {
        return (
          <h1 style={{ color: "green", fontSize: "45px" }} className='clock'>{(machineData[mech_id].on_time)}</h1>
        )
      }
      else if (machineData[mech_id].efficiency >= 40) {
        return (
          <h1 style={{ color: "orange", fontSize: "45px" }} className='clock'>{(machineData[mech_id].on_time)}</h1>
        )
      }
      else {
        return (
          <h1 style={{ color: "red", fontSize: "45px" }} className='clock'>{(machineData[mech_id].on_time)}</h1>
        )
      }
    }


  }

  const machineStatus = (mech_id) => {
    // console.log(mech_id, machineData[mech_id])
    if (machineData[mech_id].machine_current_status === "D") {
      return (
        <div style={{ color: "blue", backgroundColor: 'blue', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
      )
    }
    else {
      if (machineData[mech_id].machine_current_state === "0") {
        return (
          <div style={{ color: "red", backgroundColor: 'red', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
        )
      }
      else if (machineData[mech_id].machine_current_state === "1") {
        return (
          <div style={{ color: "orange", backgroundColor: 'orange', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
        )
      }
      else {
        return (
          <div style={{ color: "green", backgroundColor: 'green', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
        )
      }
    }


  }


  const updateOnTimeInSec = () => {

    if (Object.keys(machineData).length) {
      setMachineData((prevData) => {
        const updatedData = { ...prevData };

        for (let key of graph) {
          if (updatedData[key.sensor_data._id].machine_current_state === '2') {
            const timeString = updatedData[key.sensor_data._id].on_time;
            const timeObject = moment(`2000-01-01 ${timeString}`, 'YYYY-MM-DD HH:mm:ss');

            // Add one second to the time
            timeObject.add(1, 'second');

            // Format the updated time string
            const newTimeString = timeObject.format('HH:mm:ss');

            // Create a new object for the specific item and update the on_time property
            updatedData[key.sensor_data._id] = {
              ...updatedData[key.sensor_data._id],
              on_time: newTimeString,
            };
          }
        }

        return updatedData;
      });
    }

  }

  const borderRadius = (serialNo, machineId) => {
    // console.log(serialNo, machineId ,colorWidthData[machineId].length )
    if (colorWidthData[machineId].length == 1) {
      return "10px"
    }
    else if (serialNo == colorWidthData[machineId].length - 1) {
      // console.log(colorWidthData[machineId])
      return " 0 10px 10px 0 "
    }
    else if (serialNo == 0) {
      return " 10px 0 0 10px"
    }


  }

  const LogOut = () => {
    removeTvDetails()
    removeUserDetails()
    console.log("logout")
    navigate("/");
  }

  clearInterval(intervalId);
  intervalId = setInterval(() => {
    updateOnTimeInSec();
  }, 1000);



  const clearErrorMsg = () => {
    setError(undefined);
  }



  useEffect(() => {
    TvMachineAssignList()
  }, []);

  return (
    <div className='TvDashboard'>
      <div className='Header'>
        <div style={{ width: "10%" }}>
          <img src={flovation} alt="flovation_Logo" />
        </div>
        <div style={{ width: "70%" }} className='com_name'>
          <h3>SuperManager</h3>
          <h4>{TvDetails.comp_name}</h4>
        </div>
        <div className="stats">
          <h2 style={{ backgroundColor: "lightGreen", border: "2px solid green" }}>{machineStates.on_load ?? 0}</h2>
          <h2 style={{ backgroundColor: "rgb(242, 187, 84)", border: "2px solid orange" }}>{machineStates.free_load ?? 0}</h2>
          <h2 style={{ backgroundColor: "rgb(245, 143, 143)", border: "2px solid red" }}>{machineStates.zero_load ?? 0}</h2>
        </div>

        <div style={{ width: "5%" }} className='signOut'>
          <i className="fa fa-sign-out" onClick={LogOut} ></i>
        </div>
      </div>

      <div className='com_name_mobile'>
        <h3>SuperManager</h3>
        <h4>{TvDetails.comp_name}</h4>
      </div>

      <div className='machines'>

        {graph.length ?
          (graph.map((item, index) => (
            <div key={index} className='machine_card'>
              <div className='row1'>
                <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                  <h4 style={{ textAlign: "center", width: "90%" }}>{item.sensor_data.sensor_name}</h4>
                  {((Object.keys(machineData).length) !== 0 && (machineData[item.sensor_data._id])) ? (machineStatus(item.sensor_data._id)) : ("")}
                </div>
                <div style={{ marginTop: "5px", display: "flex", width: "100%" }}>
                  <h6 style={{width: "40%"}}>{item.device_name} </h6>
                  <div style={{display: "flex", width: "60%", textAlign: "right", justifyContent: "flex-end" }}>
                    <h6 style={{ color: "#5b5656", marginRight: "10px", textAlign: "right"  }}>Shift</h6>
                    <h6 style={{ textAlign: "right" }}>{((Object.keys(machineData).length) != 0 && (machineData[item.sensor_data._id])) ? (machineData[item.sensor_data._id].start_time + " - " + machineData[item.sensor_data._id].end_time) : ("NA")}</h6>
                  </div>
                </div>
              </div>
              <hr className='line'></hr>

              <div className='row2'>
                <h5 style={{ color: "#5b5656" }}>Run Time</h5>
                {/* <h1 style={{ color: "red", fontSize: "45px" }}>00:00:00</h1> */}
                {((Object.keys(machineData).length) != 0 && (machineData[item.sensor_data._id])) ? (machineTime(item.sensor_data._id)) : ("--:--:--")}
                <h5 style={{ marginTop: "45px" }}>{((Object.keys(machineData).length) != 0 && (machineData[item.sensor_data._id])) ? (machineData[item.sensor_data._id].efficiency) : ("NA")}%</h5>
              </div>

              <div style={{ display: 'flex', width: '100%', height: '10px', borderRadius: '10px', marginBottom: "3px" }}>
                {(Object.keys(colorWidthData).length && (colorWidthData[item.sensor_data._id])) ?
                  colorWidthData[item.sensor_data._id].map(({ color, width }, index) => (
                    <div key={index} style={{ width, backgroundColor: color, borderRadius: (borderRadius(index, item.sensor_data._id)) }} ></div> //(index == 0 ? '10px 0 0 10px' : (index == colorWidthData[item.sensor_data._id].length - 1 ? '0px 10px 10px 0px' : '0px')) 

                  )) :
                  <div key={index} style={{ width: "100%", backgroundColor: "gray", borderRadius: "10px", height: "10px" }} ></div>}
              </div>

              <hr className='line'></hr>

              <div className='row3' style={{ alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  <h6 style={{ color: "#5b5656" }}>Best</h6>
                  <h6>{((Object.keys(machineData).length) != 0 && (machineData[item.sensor_data._id])) ? (machineData[item.sensor_data._id].best_run_by) : ("NA")}</h6>
                </div>
                <h6>{((Object.keys(machineData).length) != 0 && (machineData[item.sensor_data._id])) ? (machineData[item.sensor_data._id].best_shift_efficency) : ("NA")}%</h6>

              </div>

              {/* <div className='row4' style={{ display: 'flex', width: '100%', height: '20px', borderRadius: '5px' }}>
                {(Object.keys(colorWidthData).length && (colorWidthData[item.sensor_data._id])) ?
                  colorWidthData[item.sensor_data._id].map(({ color, width }, index) => (
                    <div key={index} style={{ width, backgroundColor: color, borderRadius: (index == 0 ? '10px 0 0 10px' : (index == colorWidthData[item.sensor_data._id].length - 1 ? '0px 10px 10px 0px' : '1px')) }} ></div>
                  )) :
                  <div key={index} style={{ width: "100%", backgroundColor: "gray", borderRadius: "10px" }} ></div>}
              </div> */}
            </div>
          ))) : (<></>)

        }

        {/* <p>{error}</p> */}

      </div>


    </div>
  )
}

export default TvDashboard